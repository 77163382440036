import React, { Component } from 'react'
import DataAPI from '../../api/DataAPI';
import "./forget.scss"
export default class Forget extends Component {

    constructor(){
        super();
        this.state={
            email:"",
            auth:"",
            passwd:"",
            passwd2:""
        }
        this.handleSearch=this.handleSearch.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleChangepass=this.handleChangepass.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }


    async handleSearch(e){
        try{
        if(this.state.email.trim() === ""){
            alert("Please provide email address");
            return;
        }

        let filter={
            email: this.state.email
        }

        const pdata = await new DataAPI().forgetPassword(filter);
        console.log("Response received for given id:", pdata);
        // if (pdata !== undefined && pdata.success === true){
        //     alert("Password: " + (pdata.data));
        // }
        alert("Message: " + (pdata));
    }catch(err){
        console.error(err)
    }

    }


    handleChange(e){
        this.setState({email: e.target.value});
        //console.log(this.state.email)
    }

    handleChangepass(e)
    {
        if(e.target.id === "cp-auth-1"){
            this.setState({auth: e.target.value})
        } else if(e.target.id === "cp-pawwwd-1"){
            this.setState({passwd: e.target.value})
        }if(e.target.id === "cp-pawwwd-2"){
            this.setState({passwd2: e.target.value})
        } else {

        }

    }


    async handleSubmit(e){
        //e.preventDefault();
        let aa= this.state.auth.trim();
        if(aa.length < 8){
            alert("Invalid auth token")
            return ;
        }

        if (this.state.passwd !== this.state.passwd2){
            alert("Password mismatch. Please try again.")
            return;
        }

        let filter ={
            "auth": this.state.auth,
            "password": this.state.passwd,
            "email": this.state.email
          }
    
        const pdata=await new DataAPI().resetPassword(filter);
        
        console.log("Change Password: ",pdata);
    
        if(pdata.success === true){
            alert("Password  updated successfully.")
            this.setState({
                email:"",
                auth:"",
                passwd:"",
                passwd2:""
            }
            );
        }        

    }

    render() {
        //console.log("CreateUser Render: ", this.state)
        return (
            <>
            <div className="forget">
                <div className="top">
                    <h1>Generate Password</h1>
                </div>
            </div>

            <div className="forget">
                <div className="bottom">
                    <div className="left">
                        <div className="mykey">
                            <span className="material-symbols-outlined">key</span>
                        </div>
                    </div>
                    <div className="right">
                        <form>
                            <div className="formInput">
                                <input type="email" className="form-control" onChange={this.handleChange} id="cu-email-txt" 
                                    placeholder="Registered email address" aria-label="email address" aria-describedby="cu-email-btn" value={this.state.email}/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={this.handleSearch} id="cu-email-btn">Generate Token</button>
                                </div>                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="changepwd">
                <div className="bottom">
                    <div className="left">
                        <div className="mykey">
                            <span className="material-symbols-outlined">key</span>
                        </div>
                    </div>
                    <div className="right">
                        <form className='chpasswd'>

                            <div className="formInput">
                                <input type="email" className="form-control" onChange={this.handleChange} id="cu-email-txt1" 
                                    placeholder="Registered email address" aria-label="email address" aria-describedby="cu-email-btn" value={this.state.email}/>
                            </div>                                

                                <div className="form-group">
                                <label htmlFor="cp-auth-1"><b>Token</b></label>
                                <input type="text" onChange={this.handleChangepass} className="form-control" 
                                    id="cp-auth-1" placeholder="Auth Token" value={this.state.newpassword}/>
                                </div>
                                <div className="form-group">
                                <label htmlFor="cp-pawwwd-1"><b>Password</b></label>
                                <input type="password" onChange={this.handleChangepass} className="form-control" 
                                    id="cp-pawwwd-1" placeholder="Confirm Password" value={this.state.newpassword}/>
                                </div>
                                <div className="form-group">
                                <label htmlFor="cp-pawwwd-2"><b>Password</b></label>
                                <input type="password" className="form-control" onChange={this.handleChangepass} 
                                    id="cp-pawwwd-2" placeholder="Confirm Password" value={this.state.newpassword2}/>
                                </div>
                                <div className="form-group">
                                <button type="submit" onClick={this.handleSubmit} >Change Password</button>                              
                                </div>
                        </form>
                    </div>
                </div>
            </div>            
            </>
        )
    }
}


