import React, { Component } from 'react'
import Chart from '../../components/chart/Chart'
import Featured from '../../components/featured/Featured'
import Widget from '../../components/widget/Widget'

import "./dashboard.scss"

export default class Dashboard extends Component {
  render() {
    //console.log("Home: ",this.context);
    return (
      <div className='dashboard'>
            <div className="top">
                <h1>My Dashboard </h1>
            </div>
          <div className="widgets">
              <Widget type="science"/>
              <Widget type="maths"/>
              <Widget type="physics"/>
              <Widget type="chemistry"/>
          </div>
          <div className='charts'>
            <Featured className="featured"/>
            <Chart className="chart"/>
          </div>
          <div className='message'>
                <h1>WORK HARD & WISELY</h1>
            </div>          
        </div>
    )
  }
}
