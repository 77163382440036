
import React from 'react'
import "./navbar.scss"
import avatar from '../../data/avatar.png';
import avatar6 from '../../data/avatar6.png';
import {Link, useNavigate} from "react-router-dom"
import Auth from '../../context/Auth';


export default function Navbar (props) {
    
  const navigate = useNavigate();
  function handleLogout()
  {
    logOut();
    navigate("/home")
  }


  function handleSidebar()
  {
    //console.log("handleSidebar")
    if (props.sstate === undefined) {
      navigate("/user")
      return;
    }
    props.sstate();
  }

  let welcome="";
    let avtar = ""
    let link="/login"
    let olog=<span/>
    let auth=new Auth()
    var male = auth.getdata('user');
    const pops=auth.getContext()

    const {username, isAuthenticated,logOut} = pops;


    if(isAuthenticated === true || isAuthenticated === 'true' ){
      welcome=username.split('@')[0].toUpperCase()
      avtar=<span className='icon'><img src={male?avatar:avatar6} alt="" className='avatar'/></span>
      olog=<div className="item" onClick={handleLogout}><span className="material-symbols-outlined" >logout</span></div>
      link="/user"
    } else {
      welcome="SIGN IN";
      avtar=<span className="material-symbols-outlined">account_circle</span>
    }
  
  
  
  
    return (


      <div className='navbar'>
        <div className="wrapper">

          <div className="item">
            <Link to="/" >
            <img src="./logo512.png" alt=""/>
            </Link>
            <span className="material-symbols-outlined" id="navtoggle_sb" onClick={handleSidebar} style={{cursor:'pointer'}}>menu</span>
          </div>

          <div className="items">
            {/* <div className="item">
            <span>English</span>
            <span className="material-symbols-outlined">Language</span>
            </div>

            <div className="item">
            <span className="material-symbols-outlined">notifications</span>
            <span className="counter">1</span>
            </div> */}
            {/* <div className="item">
            <span className="material-symbols-outlined">more_vert</span>
            </div> */}
            {/* <div className="item_login" onClick={handleLogin} > */}
            <div className="item">
              <Link to={link} className="item_login" >
              {avtar}
              <span className='userlogin'>{welcome}</span>
              </Link>
            </div>           
            { olog}
        </div>
      </div>
      </div>
    )
}

