import React, { Component } from 'react'
import Auth from '../../context/Auth';
import DataAPI from '../../api/DataAPI';
import FormComSubjects from '../../components/FormComSubject';
import {syllabus} from "../../data/data"
import "./testpaper.scss"


/*
Filter:  {
  subjects: { '$in': [ 'maths' ] },
  parentid: { '$ne': '0' },
  complex: { '$lte': 5 },
  topics: { '$in': [ 'number system' ] },
  tags: { '$in': [ 'VI' ] }
}
*/
export default class TestPaper extends Component {

    constructor(){
        super();
        var auth=new Auth();
        this.state={
            name:"",
            classname:auth.getClass(), 
            basequest:[],
            topics:[],
            tags:[auth.getClass()],
            selectedTopics:[],
            subject:"",
            topic1:"",
            tag1:auth.getClass(),
            complex: 0,
            count:15,
            loading: false

        }

        this.onChangeSubject=this.onChangeSubject.bind(this);
        this.onClickApply=this.onClickApply.bind(this);
        this.onChangeTopic=this.onChangeTopic.bind(this);
        this.handleTagOnChange=this.handleTagOnChange.bind(this);
        this.getComplexity=this.getComplexity.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
        this.updateCount=this.updateCount.bind(this);
        this.updateName=this.updateName.bind(this);
        this.resetState=this.resetState.bind(this);
    }

    resetState(){
        this.setState({
            name:"",
            basequest:[],
            topics:[],
            tags:[],
            selectedTopics:[],
            subject:"",
            topic1:"",
            tag1:"",
            complex: 0,
            count:15,
            loading: false

        });

    }

    updateName(e){
        if(e.target.value !== undefined){
            this.setState({name:e.target.value})
        }

    }

    updateCount(e){
        if(e.target.value !== undefined){
            this.setState({count:e.target.value})
        }

    }

    async onSubmit(){

        if (this.state.name === "" || this.state.name === undefined){
            alert("Empty Name field.")
            return;
        }
        //console.log("=======================> Onsubmit: ", this.state)
        let auth = new Auth();
        let email=auth.getEMail();
        let udata={
            name: this.state.name,
            email: email,
            classtag: auth.getTag(),
            subject: this.state.subject,
            topics:this.state.basequest,
            tags: this.state.tags,
            complex: this.state.complex,
            count: this.state.count
        }
        new DataAPI().savePaper(udata, this, (obj, pdata)=>{
            console.log("Response received for given id:", pdata);
            if (pdata.success === true){
                alert("Added Successfully");
                obj.resetState();
            } else {
                let msg = pdata.data;
                alert(msg);
            }
        });
        
    }


    getComplexity(e){
        if(e.target.value !== undefined){
            this.setState({complex:e.target.value})
        }
    }

    async onChangeSubject(sdata){
        //console.log("FormTestPaper: ",sdata);


        if(sdata){
            //const pdata=await new DataAPI().getTopics(sdata)

            let name=sdata
            name=name+"/tags/"+new Auth().getClass();

            new DataAPI().getTopics(name, this,function(obj, pdata){
            if(pdata.success === true){
            //this.state.;
            //let newdata = pdata.data[0].sort();
            obj.setState({
                topics: pdata.data[0],
                subject: sdata,
                loading: true,
                basequest:[]
            })
            }else{
                alert(pdata.data);
            }
        });
        }
    }


    onChangeTopic(e){
        this.setState({topic1:e.target.value});

    }

    handleTagOnChange(e){
        this.setState({tag1:e.target.value});
    }

    onClickApply(e){
        if (e.target.id === "ftp-topic"){
            let val = this.state.topic1
            if (val === ""){
                alert("Empty topic data");
                return;
            }
            let ff = this.state.basequest.includes(val);
            if( ff === false){
                let newlist=this.state.basequest;
                newlist.push(val)
                this.setState({
                    basequest:newlist})
            } else {
                alert("duplicate "+ val)
            }
    
        } else if (e.target.id === "ftp-tag"){


            console.log("Tags----------------->",this.state.tags)
            let tlist = this.state.tags;
            let nlist = this.state.tag1.toUpperCase().split(',');
            if(nlist.length>0){
                
                nlist.forEach(value => {
                    if (tlist.indexOf(value) === -1) {
                        tlist.push(value);
                    }
                  });
            }

            console.log("TLIST",tlist)
            const unique_list = [];

            tlist.forEach(value => {
              if (unique_list.indexOf(value) === -1) {
                unique_list.push(value);
              }
            });
            
            console.log("unique_list: ",unique_list);

            this.setState({tags:unique_list})

        } else  {

        }
    }

  render() {
    //console.log("Formsearchrequest: render ...State: ", this.state)

    //let cls = new Auth().getClass();

    let topicList = "" ;
    let count=1;
    if(this.state.topics && this.state.topics.length > 0){
        topicList = this.state.topics.map((note) => <option key={note +"ftp-1"} value={note}>{note}</option> );
    }

    let baseQuestList =  <option key={"none-ftp-1"}  value="None">None</option> ;
    
    

    if(this.state.basequest!==undefined && this.state.basequest.length > 0){
        console.log("Start rendering Formpaper search: ", this.state.basequest);
        baseQuestList = this.state.basequest.map((note) => <li className="list-group-item" key={note}>{note}</li> );
    } 

    //if (syllabus === undefined) return(<>No syllabus found</>);
    let selsub = "maths";
    if (this.state.subject !== "")
        selsub=this.state.subject
    console.log("syllabus: ", selsub);
    
    return (
        <>
        <div className='testpaper'>
            <div className="top">
                <h1>New Test </h1>
            </div>
        </div>
        <div className='testpaper'>        
            <div className="bottom">
                <div className="left">
                    <div className='items'>
                    
                        <div className='item'>
                                <div className="label">Set Name: </div>
                                <div className='nameqset'>
                                <input type="text" className="form-control" id="staticQName-1" onChange={this.updateName} 
                                    placeholder="Name of question set"  value={this.state.name}/>
                                </div>    
                        </div>
                        <div className='item'>
                            <div className="label">Subject 
                            </div>
                            <FormComSubjects title="subject" multisel={false} datafunc={this.onChangeSubject} id="ftp-subcomp"/>
                        </div>

                        <div className="item">

                            <div className="label">Topics </div>
                            <select className="form-select" aria-label="Select Topic" defaultValue={'None'} onChange={this.onChangeTopic} onClick={this.onChangeTopic}>
                            <option key={"select-topic-ftp"} value="">Select Topic</option>
                                {topicList}
                            </select>
                            <button className="button" type="button" onClick={this.onClickApply} id={"ftp-topic"}>Add</button>

                        </div>

                        <div className="item">
                            <div className="label">Class/Tags</div>
                            <input type="text" className="form-control" onChange={this.handleTagOnChange} id="ftp-statictag"  
                                placeholder="Comma seperated tags" value={this.state.tag1}/>
                            <button className="button" type="button"  onClick={this.onClickApply} id={"ftp-tag"}>Add</button>
                        </div>

                        <div className="item">
                                <div className="label">Complexity</div>
                                <select className="form-select mb-3" aria-label=".form-select-lg example" onChange={this.getComplexity} 
                                    defaultValue="0">
                                <option key="comp-0" value="0">Select Level of Complexity</option>
                                <option key="comp-1" value="1">One</option>
                                <option key="comp-2" value="2">Two</option>
                                <option key="comp-3" value="3">Three</option>
                                <option key="comp-4" value="4">Four</option>
                                <option key="comp-5" value="5">Five</option>
                                </select>                        

                        </div>


                        <div className="item">
                            <div className="label">Number of Questions</div>
                            <input type="Number" className="form-control" id="staticQCount" onChange={this.updateCount} placeholder="Max is 25" min="5" max="25" disabled/>
                        </div>


                        <div className='item'>
                            <button className="button" type="button"   onClick={this.onSubmit} id={"ftp-submit"}>Create</button>
                        </div>

                    </div>
                </div>
                <div className="right">
                    <div className='summary'>Test Template Summary</div>
                    <hr/>
                    <div className='items'>
                    {/* <div className="title">Selected Options </div> */}
                    <div className="item">
                        <div className="label">Subject </div>
                        <input type="text" readOnly className="form-control-plaintext inputs" id="staticSubject" value={this.state.subject}/>
                    </div>

                    <div className="item">
                    <div className="label">Topic/s </div>
                    <div className="list">
                        {baseQuestList}
                    </div>
                        
                    </div>

                    <div className="item">
                        <div className="label">Tags </div>
                        {this.state.tags.toString()}
                    </div>

                    <div className="item">
                        <div className="label">Complexity </div>
                        <input type="text" readOnly className="form-control-plaintext inputs" id="staticcomp" value={this.state.complex}/>
                    </div>

                    <div className="item">
                        <div className="label">No of Questions </div>
                        <input type="number" readOnly className="form-control-plaintext inputs" id="staticno" value={this.state.count}/>
                    </div>
                </div>
                </div>
            </div>
        </div>  
        <div className="syllabus">
            <div className='top'>
                <h2>Syllabus {selsub} - {this.state.classname}</h2>
            </div>
            <div className="bottom">
                {
                    
                    syllabus[selsub][this.state.classname].map((d)=>                           
                            <div className="chapter" key={"syl-"+this.state.classtag+"-"+count++}>{d.chapter} {d.name}
                                    {d.topics.map((l)=><li key={"csyl-"+this.state.classtag+"-"+count++}>{l}</li>)}
                            </div>    
                        )
                }
                </div>
        </div>
        </>
    )
  }
}
