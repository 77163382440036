import React from 'react'
import "./camplist.scss"
export default function Testmgmt(props) {




    if(props.datacol === undefined) return(<></>)

  let ind=1+props.shift;
  var count=props.histdata.length;
  //console.log("count", count)
  //console.log("count", props.histdata)
  let mgmtcolumn=props.datacol;
  let size = props.datacol.length;

  //console.log("Size: ", size);
    return (
      <div className='camplist'>

        
        <div className="datactrl">
          
          <button id="camplist_prev" type='button' onClick={props.prev}>Prev</button>        
          <div className="total">Total Records: {count}</div>
          <button id="camplist_next" type='button' onClick={props.next}>Next</button>        
        </div>
    
        <div className="datatable">
          <table>
          <thead>
            <tr>
              {
                mgmtcolumn.map(d=>(
                  <th id={d.id} style={{"width":d.width}}> {d.title}  </th>
                  ))
              }
            </tr>
          </thead>
          <tbody>
              {
                props.histdata.map((d)=>{
                    return(<tr><td>{ind++}</td>
                        <td>{d[mgmtcolumn[1].col]}</td>
                        <td>{d[mgmtcolumn[2].col]}</td>
                        <td>{d[mgmtcolumn[3].col]}</td>
                        <td>{d[mgmtcolumn[4].col]}</td>
                        <td>{d[mgmtcolumn[5].col]?"Submitted":"Pending"}</td>
                        <td>{d[mgmtcolumn[6].col]}</td>
                    </tr>)
                })
                    
                    
                //     d=>())
              }
          </tbody>
        </table>
        </div>
      </div>
    )
}

