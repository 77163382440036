
import React, { Component } from 'react'
import FormComSubject from '../FormComSubject';
import DataAPI from '../../api/DataAPI';
import "./subjectcom.scss"
import Auth, {axiosInstance} from '../../context/Auth';
export default class Subjectcom extends Component {
    
        subdata=[] //to display data in grouplist
        sublist=[] //List of sijected feteched form db
        constructor(){
            super()
    
            this.handleReset = this.handleReset.bind(this);
            this.handleSelectChange = this.handleSelectChange.bind(this);
            this.state= {
                subject:"",
                institute:"",
                subdata: this.subdata,
                sublist:[]
            };
            this.handleChangeSubject = this.handleChangeSubject.bind(this);
            this.handleChangeInst = this.handleChangeInst.bind(this);
            this.postSubjectData = this.postSubjectData.bind(this);        
        }
    
    
        async postSubjectData(e){
            e.preventDefault();
            
            const li = this.state.institute.split(';');
            const postdata={
                "title": this.state.subject,
                "type": "subject",
                "body": li
              }        
              let tock = new Auth().getToken();  
              axiosInstance.post('/contents/content', postdata,
                    {
                        headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                        'auth-token': tock}
                    }).then(
                response=>{
                    if(response.data.success === true){
                        alert("Subject created successfully")
                        this.setState({
                            subject:"",
                            institute:""
                        })
                    }
                }
              ).catch(error=>{
                console.log(error)
              })
    
            }
    
        handleChangeSubject(changeText){
            this.setState(
                {
                    subject:changeText.target.value
                }
            );
        }
        
        handleChangeInst(changeText){
            this.setState(
                {
                    institute:changeText.target.value
                }
            );
        }
    
    
        handleReset() {
            this.setState({
                sublist:[]
            });  
          }
    
        handleSelectChange = selectedOption => {
            const arr=this.state.sublist;
            if (arr.indexOf(selectedOption.target.value)<0){
                arr.push(selectedOption.target.value)
            }
            this.setState({
                sublist:arr
            });        
        };
    
        async componentDidMount(){

            //const pdata = await new DataAPI().getAllSubjects()
            new DataAPI().getAllSubjects(this, (obj, pdata)=>{
            obj.setState({
                subdata: pdata.data.sort(),
                loading: true
            })
        });
    
          }
    
    
    
      render() {
        //const subjectList = this.state.subdata.map((note) => <li key={note} className="list-group-item">{note}</li> );
        //console.log("Render Formsubject");
        if (this.props.ctype ==='box'){//List all subjects
            return(<></>)
        } else {
            //Dropdoen menu
        return (
                <>
            <div className="subjectcom">
            <div className="top">
                <h1>Configure Subjects</h1>
            </div>
            <div className="bottom">
                <div className="left">
                    <form>
                        <div className="form-group">
                            <label htmlFor="inputSubject">Subject Name</label>
                            <input type="text" className="form-control" id="subname1" onChange={this.handleChangeSubject} 
                                aria-describedby="Subject Name" placeholder="Subject Name"/>
                            <div className="subjectinfo">Enter the name of the subject if not present in list.</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="nameInstitute">Institute/School Name</label>
                            <input type="text" className="form-control" id="nameInstitute" onChange={this.handleChangeInst} placeholder="Institute/School Name Ex: school-1; school-2"/>
                        </div>
                        <button type="submit" className="button" onClick={this.postSubjectData}>Submit</button>
                    </form>

                </div>
                <div className="right">
                <div className="col-sm-4">
                            <h4>List of Subjects</h4>
                            <FormComSubject ctype='box'/>
                        </div>    

                </div>
            </div>

    
    </div>
    
    
                
               {/* <div className="container my-2">
                        <div className="input-group">
                        <select className="form-select" aria-label="Select Subject" defaultValue={'maths'} onChange={this.handleSelectChange}>
                        {noteItems}
                        </select>
                        <button className="btn btn-outline-secondary" type="button" onClick={this.handleReset} id={id+"-addon1"}>Reset</button>
                        </div>                      
                        <input type="text" className="form-control my-1" placeholder="subjects" value={subdata} disabled/>
                </div> */}
            
            </>
        )
    }
    
    }
    }
    
    