import React, { Component } from 'react'
import DataAPI from '../../api/DataAPI';
import {uri} from '../../context/Auth';
import "./layout.scss"

var Latex = require("react-latex");
export default class LayoutBase extends Component {
    constructor(){    
        super();
        
        this.state={
            id:"ly",
            uuid:"",
            qid:"",
            submitted:0,
            savedoption:-1,
            selected:0,
            answer:[false,false,false,false,false,false],   //4->allfalse,5->alltrue
            result:false,                                      //final answer
            anslist:[true,true,true,true],
            optid:[0,0,0,0],
            allfalse:false,
            alltrue:false,
            status: false                   //right or wrong attempt
        }
        this.imgurl=uri+"/upload/images/";
        this.onSelect=this.onSelect.bind(this);
        this.setAllFalse=this.setAllFalse.bind(this);
        this.setAllTrue=this.setAllTrue.bind(this);
        this.setOptionsData0=this.setOptionsData0.bind(this);
        this.setOptionsData1=this.setOptionsData1.bind(this);
        this.setOptionsData2=this.setOptionsData2.bind(this);
        this.setOptionsData3=this.setOptionsData3.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    setOptionsData0(e){
        let arr=this.state.answer;
        const val=(arr[0]===false)?true:false;
        let oid=this.state.optid;
        const arrid=e.target.id.split("##")
        oid[0]=arrid[0]
        arr[0]=val
        this.setState({
            answer:arr,
            optid:oid,
            qid:arrid[1],
            uuid:arrid[2]

        })
    }

    setOptionsData1(e){
        let arr=this.state.answer;
        const val=(arr[1]===false)?true:false;
        let oid=this.state.optid;
        const arrid=e.target.id.split("##")
        oid[1]=arrid[0]
        arr[1]=val
        this.setState({
            answer:arr,
            optid:oid,
            qid:arrid[1],
            uuid:arrid[2]

        })
        
    }
    setOptionsData2(e){
        let arr=this.state.answer;
        const val=(arr[2]===false)?true:false;
        let oid=this.state.optid;
        const arrid=e.target.id.split("##")
        oid[2]=arrid[0]
        arr[2]=val
        this.setState({
            answer:arr,
            optid:oid,
            qid:arrid[1],
            uuid:arrid[2]

        })
        
    }
    setOptionsData3(e){
        let arr=this.state.answer;
        const val=(arr[3]===false)?true:false;
        let oid=this.state.optid;
        const arrid=e.target.id.split("##")
        oid[3]=arrid[0]
        arr[3]=val
        this.setState({
            answer:arr,
            optid:oid,
            qid:arrid[1],
            uuid:arrid[2]

        })
        
    }


    setAllFalse(e){
        //console.log("All false: ",e.target.value);
        const arrid=e.target.id.split("##")
        if(e.target.value ==='on'){
            if(this.state.allfalse === true){
                this.setState({
                    allfalse:false,
                    answer:[false,false,false,false,false,false]
                });
            } else {
            this.setState({
                allfalse:true,
                alltrue:false,
                answer:[false,false,false,false,true,false],
                qid:arrid[1],
                uuid:arrid[2]

            })
            }
        }
        
        
    }

    setAllTrue(e){
        //console.log("All false: ",e.target.value);
        const arrid=e.target.id.split("##")
        if(this.state.alltrue === true){
            this.setState({alltrue:false,
                answer:[false,false,false,false,false,false]});
        } else {        
        if(e.target.value ==='on'){
        this.setState({
            alltrue:true,
            allfalse:false,
            answer:[true,true,true,true,false,true],
            qid:arrid[1],
            uuid:arrid[2]

        })
        }
        }
    }



    displayState(){
        const show = this.props.show;
        let showIcon=<span className="material-symbols-outlined" style={{color:"red"}}>cancel</span>
        if(show === true){
            if(this.state.result === true){
                showIcon=<span className="material-symbols-outlined"  style={{color:"green"}}>check_circle</span>
            }
        } else {

            if(this.state.submitted === 1){
                showIcon=<span className="material-symbols-outlined"  style={{color:"green"}}>save</span>
            } else {
                showIcon=<span className="material-symbols-outlined"  style={{color:"red"}}>save</span>
            }
        }

        if(this.state.selected === 1){
        return(
            <div>
                {showIcon}
                <span className="material-symbols-outlined">expand_less</span>
                </div>);
        } else {
            return(<div>
                    {
                        showIcon
                    }
                    <span className="material-symbols-outlined">expand_more</span>
            </div>);
        }
        
    }

    async handleSubmit(e){
        try{
            const ind=this.props.data.sr;

            //console.log("handleSubmit ... ", this.props.show)
            if(this.props.show ===true){
                alert("Answer sheet is already submitted.")
                return;
            }
            let count=0;
            this.state.answer.forEach(a => {
                if(a===true)count++
            })

            if(count===0){
                alert("Please select appropriate options and save");
                return;
            }
            //console.log(this.state.answer)
            //console.log(this.state.optid)

            const filter = {
                    "uuid":this.state.uuid,
                    "qid": this.state.qid,
                    "optid": this.state.optid,
                    "sid":this.props.setid,
                    "sname":this.props.setname,
                    "answer": this.state.answer
                    }
            
                    //console.log("*****************************************************");
                    //console.log(filter)
                    //console.log("*****************************************************");
            const pdata =await new DataAPI().getAnswerSet(filter);
            //console.log("RESult response: ",pdata)
            if(pdata.success === true){
                this.setState({
                    submitted:1,
                    anslist:pdata.data.anslist,
                    result:pdata.data.result,
                    status:pdata.data.status
                })
                this.props.updatefunc(ind,pdata.data.result);
                alert("Saved Successfully");
            }
        }catch(err){
            console.error("Error: ",err)
        }
        
    }


    onSelect(){
        if(this.state.selected === 1){
            this.setState({selected: 0});
        } else {
            this.setState({selected: 1});
        }
    }

    insertOption(q){
        const dis=(this.state.allfalse||this.state.alltrue)
        //console.log("insertOption Item object q: ",q, q.options)


        //console.log("this.props.show", this.props.show)
        //console.log("this.state.anslist", this.state.anslist)
        const col1=(this.props.show === true && this.state.anslist[0]===true) ?"green":"grey";
        const col2=(this.props.show === true && this.state.anslist[1]===true) ?"green":"grey";
        const col3=(this.props.show === true && this.state.anslist[2]===true) ?"green":"grey";
        const col4=(this.props.show === true && this.state.anslist[3]===true) ?"green":"grey";
        const str1={"backgroundColor":col1, color:"white"};
        const str2={"backgroundColor":col2, color:"white"};
        const str3={"backgroundColor":col3, color:"white"};
        const str4={"backgroundColor":col4, color:"white"};
        
        const latstr1=`$$${q.options[0].name}$$`
        const latstr2=`$$${q.options[1].name}$$`
        const latstr3=`$$${q.options[2].name}$$`
        const latstr4=`$$${q.options[3].name}$$`
        return (
            <div className="options">
                <div className="optionrow">
                    <div className="option">
                    <label className="label">[{1}]</label>
                        <input className="check" type="checkbox" onChange={this.setOptionsData0} 
                            id={q.options[0].id+"##"+q.qid+"##"+q.uuid} aria-label="Checkbox for following text input" disabled={dis} checked={this.state.answer[0]}/>
                        { (q.options[0].maths === false || q.options[0].maths === undefined)?
                        <input className="text" type="text" id={this.state.id+"##"+0} onChange={this.setOptionsData}
                            aria-label="Text input with checkbox" style={str1} value={q.options[0].name} readOnly/>:
                        <div className="latex" style={str1}><Latex>{latstr1}</Latex></div>
                        }
                    </div>
                    
                    <div className="option">
                        <label className="label">[{2}]</label>
                        <input className="check" type="checkbox" onChange={this.setOptionsData1} 
                            id={q.options[1].id+"##"+q.qid+"##"+q.uuid} aria-label="Checkbox for following text input" disabled={dis} checked={this.state.answer[1]}/>
                        { (q.options[1].maths === false || q.options[1].maths === undefined)?
                        <input type="text" id={this.state.id+"##"+1} className="text" onChange={this.setOptionsData}
                            aria-label="Text input with checkbox" style={str2}  value={q.options[1].name} readOnly/>:
                            <div className="latex" style={str2}><Latex>{latstr2}</Latex></div>
                        }
                    </div>
                </div>    
                <div className="optionrow">
                    <div className="option">
                        <label className="label">[{3}]</label>
                            <input className="check" type="checkbox" onChange={this.setOptionsData2} 
                                id={q.options[2].id+"##"+q.qid+"##"+q.uuid} aria-label="Checkbox for following text input" disabled={dis} checked={this.state.answer[2]}/>
                        { (q.options[2].maths === false || q.options[2].maths === undefined)?
                            <input type="text" id={this.state.id+"##"+2} 
                            className="text" onChange={this.setOptionsData}
                                aria-label="Text input with checkbox" style={str3}  value={q.options[2].name} readOnly/>:
                            <div className='latex' style={str3}><Latex>{latstr3}</Latex></div>
                        }
                    </div>

                    <div className="option">
                        <label className="label">[{4}]</label>
                            <input className="check" type="checkbox" onChange={this.setOptionsData3} 
                                id={q.options[3].id+"##"+q.qid+"##"+q.uuid} aria-label="Checkbox for following text input" disabled={dis} checked={this.state.answer[3]}/>
                        { (q.options[3].maths === false || q.options[3].maths === undefined)?
                        <input type="text" id={this.state.id+"##"+3} 
                        className="text" onChange={this.setOptionsData}
                            aria-label="Text input with checkbox" style={str4}  value={q.options[3].name} readOnly/>:
                            <div className='latex' style={str4}><Latex>{latstr4}</Latex></div>
                        }
                    </div>
                </div>    
                <div className="optionrow">
                    {this.insertOptionNone(5,q)}
                    {this.insertOptionAll(6,q)}
                </div>
            </div>
        )
    }

    insertOptionNone(ind,q){
    const uuid=String("none")+String("##")+q.qid+String("##")+q.uuid;
    return (
        <div className="option">
            <label className="label">[{5}]</label>
            <input className="check" type="checkbox" onChange={this.setAllFalse} 
                id={uuid} 
                aria-label="Checkbox" checked={this.state.allfalse}/>
            <input type="text" id={this.state.id+"-staticopttext-none-"+ind} onChange={this.setOptionsData} 
                className="text" aria-label="None" value="Option 1,2,3,4 are FALSE" disabled/>
        </div>
    )
    }      

    insertOptionAll(ind,q){
    //const str={color:"red"};style={str} 

    
    return (
        <div className="option">
            <label className="label">[{6}]</label>
            <input className="check" type="checkbox" onChange={this.setAllTrue} 
                id={"all"+String("##")+q.qid+String("##")+q.uuid} aria-label="Checkbox for following text input" checked={this.state.alltrue}/>
            <input type="text" id={this.state.id+"-staticopttext-all-"+ind} onChange={this.setOptionsData} 
                className="text" aria-label="None" value="Option 1,2,3,4 are TRUE" disabled/>
        </div>
    )
    }      

    displayOptions(q){
        const dis=(this.state.allfalse||this.state.alltrue)
        return(
            <div className="options">
                {this.insertOption(1,q,dis)}
                {/* {this.insertOption(2,q,dis)}
                {this.insertOption(3,q,dis)}
                {this.insertOption(4,q,dis)} */}

            </div>            
        );
    }

    displaySubmitInfo()
    {
        return (
            <>
            <div className="bi bi-check-circle-fill" align="right" style={{fontSize:"14px", color:"red"}}>Not Submitted</div>
            </>
        );
    }

    displayStars(d){

        if (d === undefined) return;
        const comp=d.complex;
        let data=[];
        
        for (let i = 0; i < comp; i++) {
            data.push(
                <span className="material-symbols-outlined active">star_rate</span>
            )
        }
        for (let i = comp; i < 5; i++) {
            data.push(
                <span className="material-symbols-outlined inactive">star</span>
            )
        }


        return(
            <div className='star'>
                    {data}                    
            </div> 
    )
  }
}
