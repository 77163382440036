import React from 'react'
import "./footerpage.scss"
export default function FooterPage() {

    return (
      <div className='footerpage'>

        <dib className="bottom">
          {/* <div className="left">
            <h3>Office Address:</h3>
          </div>
          
          <div className="right">
          <h3>Office Map:</h3>
          </div>
             */}
        </dib>

        <div className="declaration">
        © 2022. All Rights Reserved.
        </div>
        <div className="pixaway">
          Thanks to pixabay.com for images.
        </div> 

      </div>
    )}
