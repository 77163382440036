import React, { Component } from 'react'
import axios from 'axios';
import "./uploadimage.scss"    
import {uri} from '../context/Auth';







export default class Uploadimage extends Component {

  
      constructor(){
        super();
        this.onFileUpload = this.onFileUpload.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
               
        this.state={
            selectedFile: []
        }
      }

      onFileChange = event => {
        
        // Update the state
        const len=event.target.files.length;
        console.log("Number of files ", len)
        for(let i=0;i<len;i++){
          console.log("File Details: ",event.target.files[0])  
        }
        console.log(event.target.files[0])
        this.setState({
            selectedFile: event.target.files
        });
     
      };
    
      onFileUpload = async (event) => {
        event.preventDefault()
        const url = uri+'/upload/images';
        const formData = new FormData();
        formData.append('photo', this.state.selectedFile[0]);
        formData.append('fileName', this.state.selectedFile[0].name);

        


        //console.log("formData: ",formData)
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        await axios.post(url, formData, config).then((response) => {
          console.log(response.data);
          if(response.data.success === true){
            alert("image uploaded successfully");
            this.setState({
              selectedFile: []
            })
          }
        });  
      };
      
   
    render() {
    return (
          <div className="uploadimage">
            <form onSubmit={this.onFileUpload}>
            <input className="form-control" type="file" accept="image/*" onChange={this.onFileChange} id="formFileMultiple"/>
            {/* <input type="submit" className="button" onClick={this.onFileUpload} value="Upload Image"/> */}
            <input type="submit" className="button" value="Upload Image" />

            </form>
          </div>
    )
  }
}