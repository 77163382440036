import React from 'react'
import { edufeature } from '../../data/data'

import "./testimonials.scss"
export default function Testimonials() {
  return (
    <div className='testimonials' id="testimonials">
      <div className="top">
        <div className="scope">
          For Class VI to XII
        </div>
        <div className='subtitle'>Building Confidence</div>
      </div>
      <div className="bottom">
        {edufeature.map(d=>(
        <div className="card">
        <div className="icon">
          <span className="material-symbols-outlined">{d.icon}</span>
        </div>
        <div className="title">
          <h3>{d.title}</h3>
        </div>
        <div className="description">
          {d.desc}
        </div>
      </div>

        ))}

      </div>

    </div>
  )
}
