import React, { Component } from 'react'
import DataAPI from '../../api/DataAPI';
import Auth from '../../context/Auth';
import "./newuser.scss"
export default class Profile extends Component {

    constructor(){
        super();

        const auth = new Auth();

        this.handleSearch=this.handleSearch.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleUpdate=this.handleUpdate.bind(this);

        this.state={
            userid:"",
            email:auth.getEMail(),
            fname:"",
            lname:"",
            sex:"male",
            level:"IX - Ninth Standard", //class/standard
            addr1:"",
            addr2:"",
            city:"",
            state:"",
            country:"",
            zip:"",
            passwd1:"",
            passwd2:"",
            id:"",
            search: false
        };
    }


    async  handleUpdate(){
        try{
            const newdata = {
                id: this.state.id,
                username: this.state.userid,
                email: {address: this.state.email},
                profile:{
                    firstName: this.state.fname,
                    lastName: this.state.lname,
                    sex: this.state.sex,
                    level: this.state.level, 
                    address:{
                        street1: this.state.addr1,
                        street2: this.state.addr2,
                        city: this.state.city,
                        state: this.state.state,
                        country: this.state.country,
                        zip: this.state.zip
                    }
                }
            };

            //console.log("Ready data to send on network: ", newdata);

            const pdata = await new DataAPI().updateUser(newdata);
            console.log("Response received for given id:", pdata);
            if (pdata.success === true){

            }

        }catch(err){
            console.log(err);
        }
    }

    async handleSearch(e){
        try{
        if(this.state.email.trim() === ""){
            alert("Please provide email address");
            return;
        }

        let filter={
            email: this.state.email
        }

        const pdata = await new DataAPI().getUser(filter);
        //console.log("Response received for given id:", pdata);
        if (pdata !== undefined && pdata.success === true){
            //console.log("Success ...............")
            this.setState({
                userid:pdata.data.userid,
                email:pdata.data.email,
                fname:pdata.data.fname,
                lname:pdata.data.lname,
                sex:pdata.data.sex === undefined?'male':pdata.data.sex,
                level:pdata.data.level === undefined?'IX - Ninth Standard':pdata.data.level,
                addr1:pdata.data.addr1,
                addr2:pdata.data.addr2,
                city:pdata.data.city,
                state:pdata.data.state,
                country:pdata.data.country,
                zip:pdata.data.zip,
                id:pdata.data.id,
                search: true
            })
        }
    }catch(err){
        console.error(err)
    }

    }

    async  handleSubmit(e){
        if(this.state.passwd1.trim() === "" || this.state.passwd1 !== this.state.passwd2){
            console.log("Password mismatch. please re-enter your password")
            alert("Password mismatch. please re-enter your password");
            return;
        }
        const newdata = {
            username: this.state.userid,
            password: this.state.passwd1,
            email: {address: this.state.email},
            profile:{
                firstName: this.state.fname,
                lastName: this.state.lname,
                sex: this.state.sex,
                level: this.state.level, 
                address:{
                    street1: this.state.addr1,
                    street2: this.state.addr2,
                    city: this.state.city,
                    state: this.state.state,
                    country: this.state.country,
                    zip: this.state.zip
                }
            }
        }
        console.log("Ready data to send on network: ", newdata);

        const pdata = await new DataAPI().createUser(newdata);
        console.log("Response received for given id:", pdata);
        if (pdata.success === true){
            console.log("User created successfully")
            alert("User created successfully");
            this.setState({
                userid:"",
                email:"",
                fname:"",
                lname:"",
                sex:"male",
                level:"IX - Ninth Standard", //class/standard
                addr1:"",
                addr2:"",
                city:"",
                state:"",
                country:"",
                zip:"",
                passwd1:"",
                passwd2:"",
                id:""
                });
            
        }
    }

    handleChange(e){

        console.log("id  :",e.target.id, " Value: ", e.target.value);
        if(e.target.id ==="cu-email-txt"){
            this.setState({email:e.target.value});
        } else if(e.target.id ==="cu-userid"){
            this.setState({userid:e.target.value});
        } else if(e.target.id ==="cu-pawwwd-1"){
            this.setState({passwd1:e.target.value});
        } else if(e.target.id ==="cu-pawwwd-2"){
            this.setState({passwd2:e.target.value});
        } else if(e.target.id ==="cu-fname"){
            this.setState({fname:e.target.value});
        } else if(e.target.id ==="cu-lname"){
            this.setState({lname:e.target.value});
        } else if(e.target.id ==="cu-lname"){
            this.setState({lname:e.target.value});
        } else if(e.target.id ==="cu-male"){
            this.setState({sex:'male'});
        } else if(e.target.id ==="cu-female"){
            this.setState({sex:'female'});
        } else if(e.target.id ==="cu-selclass"){
            this.setState({level:e.target.value});
        } else if(e.target.id ==="cu-inputAddress"){
            this.setState({addr1:e.target.value});
        } else if(e.target.id ==="cu-inputAddress2"){
            this.setState({addr2:e.target.value});
        } else if(e.target.id ==="cu-inputCity"){
            this.setState({city:e.target.value});
        } else if(e.target.id ==="cu-inputState"){
            this.setState({state:e.target.value});
        } else if(e.target.id ==="cu-inputZip"){
            this.setState({zip:e.target.value});
        } else if(e.target.id ==="cu-opt-level"){
            this.setState({level:e.target.value});
        } else if(e.target.id ==="cu-inputCountry"){
            this.setState({country:e.target.value});
        } else{
            console.log("NOT FOUND ------------- id  :",e.target.id, " Value: ", e.target.value);
        }
        
    }


    render() {
        
        return (
            <>
            <div className="newuser">
                <div className="top">
                    <h1>User Profile</h1>
                </div>
            </div>

            <div className="newuser">
                <div className="bottom">
                    <div className="left">
                    <div className="mykey">
                            <span className="material-symbols-outlined">manage_accounts</span>
                        </div>
                    </div>
                    <div className="right">
                        <form>
                            <div className="formInput">
                                <input type="email" className="form-control" onChange={this.handleChange} id="cu-email-txt" 
                                    placeholder="E-mail" aria-label="email address" aria-describedby="cu-email-btn" value={this.state.email} disabled/>
                            </div>
                            <div className="formInput" >
                                <label htmlFor="cu-userid" hidden><b>UserID (Required)</b></label>
                                <input type="text" className="form-control" onChange={this.handleChange} id="cu-userid" value={this.state.userid} disabled/>
                            </div> 

                            <div className="formInput">
                                <label htmlFor="cu-pawwwd-1"><b>Password</b></label>
                                <input type="password" className="form-control" id="cu-pawwwd-1"  onChange={this.handleChange} 
                                    value={this.state.passwd1} placeholder="Password" disabled={this.state.search}/>
                            </div>

                            <div className="formInput">
                                <label htmlFor="cu-pawwwd-2"><b>Rewrite Password</b></label>
                                <input type="password" className="form-control" id="cu-pawwwd-2"  onChange={this.handleChange}  
                                    value={this.state.passwd2} placeholder="Confirm Password" disabled={this.state.search}/>

                            </div>
                            <div className="formInput">
                                <label htmlFor="cu-fname">First Name</label>
                                <input type="text" className="form-control"  onChange={this.handleChange}  id="cu-fname" value={this.state.fname}/>
                            </div>
                            <div className="formInput">
                                <label htmlFor="cu-lname">Last Name</label>
                                <input type="text" className="form-control" onChange={this.handleChange}  id="cu-lname" value={this.state.lname}/>
                            </div>
                            <div className="formInputcheck">
                            <label className="form-check-label" htmlFor="cu-male">Male</label>
                                <input className="form-check-input" type="radio" name="gridRadios" id="cu-male" 
                                    onClick={this.handleChange} value='1' />
                                
                            </div>
                            <div className="formInputcheck">
                            <label className="form-check-label" htmlFor="cu-female">Female</label>
                            <input className="form-check-input" type="radio" name="gridRadios" id="cu-female" 
                                    onClick={this.handleChange} value='2'/>
                                
                            </div>
                            <div className="formInput">

                                <label className="col-sm-3">Class/Standard</label>
                                <select className="select col-sm-5" id="cu-opt-level" onChange={this.handleChange} 
                                    defaultValue={this.state.level} >
                                    <option value="VI - Sixth Standard" >VI - Sixth Standard</option>
                                    <option value="VII - Seventh Standard">VII - Seventh Standard</option>
                                    <option value="VIII - Eighth Standard">VIII - Eighth Standard</option>
                                    <option value="IX - Ninth Standard" >IX - Ninth Standard</option>
                                    <option value="X - Tenth Standard">X - Tenth Standard </option>
                                    <option value="XI - Eleventh Standard">XI - Eleventh Standard</option>
                                    <option value="XII - Twelveth Standard">XII - Twelveth Standard</option>
                                </select>
                                <input type="text" className="col-sm-4" id="cu-selclass" onChange={this.handleChange} placeholder="IX - Ninth Standard" 
                                    value={this.state.level} disabled/>

                            </div>
                            <div className="formInput">
                                <label htmlFor="inputAddress">Address</label>
                                <input type="text" className="form-control" id="cu-inputAddress" onChange={this.handleChange} 
                                    placeholder="1234 Main St" value={this.state.addr1}/>
                            </div>
                            <div className="formInput">
                                <label htmlFor="inputAddress2">Address 2</label>
                                <input type="text" className="form-control" id="cu-inputAddress2" onChange={this.handleChange}  
                                    value={this.state.addr2} placeholder="Apartment, studio, or floor"/>

                            </div>
                            <div className="formInput">
                                <label htmlFor="inputCity">City</label>
                                <input type="text" className="form-control" id="cu-inputCity" onChange={this.handleChange} value={this.state.city}/>
                            </div>
                            <div className="formInput">
                                <label htmlFor="inputState">State</label>
                                <input type="text" className="form-control" id="cu-inputState" onChange={this.handleChange} value={this.state.state}/>
                            </div>
                            <div className="formInput">
                                <label htmlFor="inputCountry">Country</label>
                                <input type="text" className="form-control" id="cu-inputCountry" onChange={this.handleChange} value={this.state.country}/>
                            </div>
                            <div className="formInput">
                                <label htmlFor="inputZip">Zip</label>
                                <input type="text" className="form-control" id="cu-inputZip" onChange={this.handleChange} value={this.state.zip}/>
                            </div>

                            <button type="button" className="button" onClick={this.handleSubmit} 
                                disabled={this.state.search}>Create New User</button>
                            <button type="button" className="button" onClick={this.handleUpdate}
                                id={this.state.id} disabled={!this.state.search}>Update</button>
                        </form>
                    </div>
                </div>
            </div>
            </>
        )
    }
}


