import { Component } from 'react'
import axios from 'axios';
export const uri=process.env.REACT_APP_API_URL; //"http://localhost:4000/v1";
export const axiosInstance = axios.create({
    baseURL:process.env.REACT_APP_API_URL,
});
/*
var jwt = require('jsonwebtoken');
const crypto = require('crypto');
const algorithm = 'aes-256-cbc'; //Using AES encryption
const key = crypto.randomBytes(32);
const iv = crypto.randomBytes(16);

function encrypt(text) {
  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
}

// Decrypting text
function decrypt(text) {
  let iv = Buffer.from(text.iv, 'hex');
  let encryptedText = Buffer.from(text.encryptedData, 'hex');
  let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}

*/
export default class Auth extends Component {
    constructor(){
        super();
        this.setLoggedInState=this.setLoggedInState.bind(this);
        this.getLoggedInState=this.getLoggedInState.bind(this);
        this.logOut=this.logOut.bind(this);
        this.getEMail=this.getEMail.bind(this);
        this.getToken=this.getToken.bind(this);
        this.getContext=this.getContext.bind(this);
        this.isSideActive=this.isSideActive.bind(this);
        this.setSideActiveState=this.setSideActiveState.bind(this);
        this.getdata = this.getdata.bind(this);
        //console.log("Server provisioned: ", uri)
    }

    setLoggedInState(data, st ){
        localStorage.setItem("loggedin",true)
        localStorage.setItem("token",data.token);
        localStorage.setItem("email",data.email);
        localStorage.setItem("sideActive",true);
        localStorage.setItem("user",data.sex);

        // console.log("setLoggedInState", data)
        let callname=data.class.split(" ")
        localStorage.setItem("class",callname[0]);
    }

    getLoggedInState(){
        const ret =  localStorage.getItem("loggedin");
        if (ret === null){
            return false;
        }
        return Boolean(ret)
    }
    
    getToken(){
        return localStorage.getItem("token");
    }
    getdata(name){
        var aa= localStorage.getItem(name);
        if(aa==='true'){
            return true;
        }
        return false;
    }
    getClass(){
        return localStorage.getItem("class");
    }

    getTag(){
        return "VI";
    }
    
    getEMail(){
        return localStorage.getItem("email");
    }

    isSideActive(){
        const ret = localStorage.getItem("sideActive");
        if(ret===null){
            return false;
        } else {
            return Boolean(ret);
        }
    }
    setSideActiveState(aa){
        if (aa===true){
            localStorage.setItem("sideActive",true);
        } else {
            localStorage.setItem("sideActive",false);
        }
    }


    getContext(){
        return({
            username:this.getEMail()===null?"":this.getEMail(), 
            isAuthenticated:this.getLoggedInState()===null?false:Boolean(this.getLoggedInState()),
            logOut:this.logOut,
            logIn:this.setLoggedInState
        }

        );
    }

    logOut(){
        //console.log("Logout done")
        localStorage.removeItem("loggedin");
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("sideActive");
        localStorage.removeItem("user");
        localStorage.removeItem("class");
    }

}

/*
{
head: "Knowing Our Numbers",
topics: ["Comparing Numbers",
    "Large Numbers in Practice",
    "Using Brackets",
    "Roman Numerals"
]	
},{
    head: "Whole Numbers",
    topics: ["Whole Numbers",
        "The Number Line",
        "Properties Of Whole Numbers",
        "Patterns in Whole Numbers"
    ]
}
Chapter 3: Playing With Numbers	3.1 Introduction
3.2 Factors and Multiples
3.3 Prime and Composite Numbers
3.4 Test For Divisibility Of Numbers
3.5 Common Factors and Common Multiples
3.6 Some More Divisibility Rules
3.7 Prime Factorisation
3.8 Highest Common Factor
3.9 Lowest Common Multiple
3.10 Some Problems on HCF and LCM
Chapter 4: Basic Geometrical Ideas	4.1 Introduction
4.2 Points
4.3 A Line Segment
4.4 A line
4.5 Intersecting Lines
4.6 Parallel Lines
4.7 Ray
4.8 Curves
4.9 Polygons
4.10 Angles
4.11 Triangles
4.12 Quadrilaterals
4.13 Circles
Chapter 5: Understanding Elementary Shapes	5.1 Introduction
5.2 Measuring Line Segments
5.3 Angles-’Right’ and ‘Straight’
5.4 Angles- ‘Acute’, ‘Obtuse’ and ‘Reflex’
5.5 Measuring Angles
5.6 Perpendicular Lines
5.7 Classification of Triangles
5.8 Quadrilaterals
5.9 Polygons
5.10 Three Dimensional Shapes
Chapter 6: Integers	6.1 Introduction
6.2 Integers
6.3 Addition of Integers
6.4 Subtraction of Integers with the help of a Number Line
Chapter 7: Fractions	7.1 Introduction
7.2 A Fraction
7.3 Fraction on the Number Line
7.4 Proper Fractions
7.5 Improper and Mixed Fractions
7.6 Equivalent Fractions
7.7 Simplest Form of a Fraction
7.8 Like Fractions
7.9 Comparing Fractions
7.10 Addition and Subtraction of Fractions
Chapter 8: Decimals	8.1 Introduction
8.2 Tenths
8.3 Hundredths
8.4 Comparing Decimals
8.5 Using Decimals
8.6 Addition of Numbers with Decimals
8.7 Subtraction of Decimals
Chapter 9: Data Handling	9.1 Introduction
9.2 Recording Data
9.3 Organisation of Data
9.4 Pictograph
9.5 Interpretation of a Pictograph
9.6 Drawing a Pictograph
9.7 A Bar Graph
Chapter 10: Mensuration	10.1 Introduction
10.2 Perimeter
10.3 Area
Chapter 11: Algebra	11.1 Introduction
11.2 Matchstick Patterns
11.3 The Idea Of  A Variable
11.4 More Matchstick Patterns
11.5 More Examples of Variables
11.6 Use Of Variables in Common Rules
11.7 Expressions with Variables
11.8 Using Expressions Practically
11.9 What is an Equation?
11.10 Solution of an Equation
Chapter 12: Ratio and Proportion	12.1 Introduction
12.2 Ratio
12.3 Proportion
12.4 Unitary Method
Chapter 13: Symmetry	13.1 Introduction
13.2 Making Symmetric Figures : Ink-blot Devils
13.3 Figures With Two Lines of Symmetry
13.4 Figures with Multiple Lines of Symmetry
13.5 Reflection and Symmetry
Chapter 14: Practical Geometry	14.1 Introduction
14.2 The Circle
14.3 A Line Segment
14.4 Perpendiculars
14.5 Angles
*/