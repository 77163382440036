
import React from 'react'

import LayoutBase from './LayoutBase'


import "./layout.scss"

var Latex = require("react-latex");
export default class Layout1 extends LayoutBase {

    constructor(){
        super();
        this.state.id="ly-1"
        
    }




  displayBody(){
    if(this.state.selected === 1){

        const data = this.props.data;
        console.log("display body: ", data)
        if(data === undefined) return(<></>)
        const latstr=`$$${data.desc}$$`
    return(
        <div className="row-body">
            <div className="row">
                <div className="question">
                    {(data.maths===true)?<>Question:  <Latex>{latstr}</Latex></>:<>Question{": "+data.title}</>}
                </div>
            </div>
            <div className="row">
                {this.insertOption(data)}
            </div>
            <div className="rowbutton" onClick={this.handleSubmit} key="">
                <div className="button" onClick={this.handleSubmit}>Save</div>
            </div>            
        </div>
    );
    } else {
        return (<div className='row-body' height="0px"></div>);
    }
  }
    
  render() {
    const data = this.props.data;
    if(data === undefined) return(<></>)
    return (
        <div className="layout">
            <div className="rowhead" onClick={this.onSelect}>
                <div className="qhead">
                    {this.displayStars(data)}
                    <b className='text-left'>Q-{data.sr}</b>
                </div>

                <div className="title">
                    {data.title}
                    
                </div>
                <div className="state">
                {/* <span class="material-symbols-outlined">expand_less</span> */}
                    {this.displayState(data)}
                </div>

            </div>        
            {this.displayBody()}
            
        </div>
                
    )
  }
}
