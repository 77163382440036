import React from 'react'
import "./aboutus.scss"
import { aboutus } from '../../data/data'
export default function AboutUs() {
  return (
    <div className='aboutus' id="aboutus">
      <div className="top">
          About Us
      </div>
      <div className="bottom">

        <div className="left">
          <img src={aboutus[0].img} alt=""/>
        </div>
        <div className="right">
          <h2>{aboutus[1].title}</h2>
          <p>{aboutus[1].desc}</p>
        </div>

      </div>
    </div>
  )
}
