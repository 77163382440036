import React, { Component } from 'react'
import DataAPI from '../api/DataAPI';
import "./uploadimage.scss"
export default class FormComSubjects extends Component {

    subdata=[]
    sublist=[]

    constructor(){
        super()
        this.state = {
            subdata: this.subdata,
            sublist:[],
            loading: false,
            updatecmp:true
        };
        this.handleReset = this.handleReset.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onChangeText=this.onChangeText.bind(this);
    }

    handleReset() {
        this.setState({
            sublist:[]
        });  
        this.props.datafunc("")
      }

      onChangeText(e){

      }
    handleSelectChange = selectedOption => {



        if(this.props.multisel === false ){
            this.setState({
                sublist:[selectedOption.target.value]
            });    
            let arr=[selectedOption.target.value]
            this.props.datafunc(arr.toString())
        } else {

            const arr=this.state.sublist;
            if (arr.indexOf(selectedOption.target.value)<0){
                arr.push(selectedOption.target.value)
                this.props.datafunc(arr.toString())
            }            
        this.setState({
            sublist:arr
        });    
        }
        
        
    };

    shouldComponentUpdate(){
        //console.log("shouldComponentUpdate")
        return this.state.updatecmp;
    }
    
    
    async componentDidMount(){
        
        //console.log("componentDidMount: Reloading FormComSubject ...")
        
        if(this.state.loading===true) return;
        //console.log("componentDidMount: Reloading FormComSubject ...1")

        new DataAPI().getAllSubjects(this, (obj, pdata)=>{
            obj.setState({
                subdata: pdata.data,
                loading: false
            })
        });
        


      }



  render() {
    let mapdata=this.state.subdata;
    if (mapdata === undefined){
        mapdata=[];
    }
    const noteItems = mapdata.map((note) => <option key={note +"fcs-1"} value={note}>{note}</option> );
    const subjectList = mapdata.map((note) => <li key={note +"fcs-2"} className="list-group-item">{note}</li> );
    
    const subdata=this.state.sublist.toString()
    const id=this.props.id

    //const subdata="this.sublist.toString()"
    //console.log("Render FormComSub: ",subdata)

    // //Control render
    // this.setState({
    //     updatecmp:false
    // })

    if (this.props.ctype ==='box'){
        //List all subjects
        return(
            <>
            <div className="container my-2">
                <ul className="list-group">
                    {subjectList}
                </ul>
            </div>
        </>

        )
    } else {
        //Dropdoen menu
    return (
                <div className="input-group">
                    {/* <label htmlFor="staticSubject" className="col-sm-2 col-form-label"></label> */}
                    <select className="form-select" aria-label="Select Subject" defaultValue={'maths'} onChange={this.handleSelectChange}>
                        <option key={"select-sub-fcs"} value="select-sub-fcs">Select Subject</option>
                            {noteItems}
                    </select>
                    <button className="button" type="button" onClick={this.handleReset} id={id+"-addon1"}>Reset</button>
                    <input type="text" className="form-control my-1" placeholder="Selected Subjects: []" onChange={this.onChangeText} value={subdata} hidden/>
                </div>                      
    )
    }

    }
}

