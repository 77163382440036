import React from 'react'
import "./camplist.scss"
import { testhistcolumn} from '../../data/data'
export default function Camplist(props) {



  const handlePrev=(e)=>{

  }

  let ind=1;
  var count=props.histdata.length;
    return (
      <div className='camplist'>

        
        <div className="datactrl">
          
          <button id="camplist_prev" type='button' onClick={handlePrev}>Prev</button>        
          <div className="total">Total Records: {count}</div>
          <button id="camplist_next" type='button' onClick={handlePrev}>Next</button>        
          
        </div>
    
        <div className="datatable">

        

          <table>
          <thead>
            <tr>
              {
                testhistcolumn.map(d=>(
                  <th id={d.id} style={{"width":d.width}}> {d.title}  </th>
                  ))
              }
            </tr>
          </thead>
          <tbody>
              {
                props.histdata.map(d=>(<tr><td>{ind++}</td>
                <td>{d.date}</td>
                <td>{d.name}</td>
                <td>{d.uuid}</td>
                <td>{d.total}</td>
                <td>{d.right}</td>
                <td>{d.wrong}</td>
                <td>{d.status}</td>
                </tr>))
              }
          </tbody>
        </table>
        </div>
      </div>
    )
}

