import React, { Component } from 'react'
import DataAPI from '../../api/DataAPI';
import "./changepass.scss"
export default class Changepasswdcom extends Component {
    constructor(){
        super();
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
                               

        this.state={
            username: "",
            password: "",
            newpassword: "",
            newpassword2: "",
            email: ""
        };
}

handleChange(e){

    console.log("ID: ", e.target.id, " value: ", e.target.value )
    if(e.target.id === 'cp-userid-txt'){
        this.setState({
            username: e.target.value
        });
    } else if(e.target.id === 'cp-email-btn'){
        this.setState({
            email: e.target.value
        });
    } else if(e.target.id === 'cp-pawwwd-old'){
        this.setState({
            password: e.target.value
        });
    } else if(e.target.id === 'cp-pawwwd-1'){
        this.setState({
            newpassword: e.target.value
        });

    } else if(e.target.id === 'cp-pawwwd-2'){
        this.setState({
            newpassword2: e.target.value
        });
    }
}

async handleSubmit(e){
    if(this.state.newpassword !== undefined && 
        this.state.newpassword2 !== undefined && 
        this.state.newpassword !== this.state.newpassword2){
        alert("New password mismatch")
        return;
    }

    let filter ={
        "username": this.state.username,
        "password": this.state.password,
        "newpassword": this.state.newpassword,
        "email": {"address":this.state.email}
      }

    const pdata=await new DataAPI().changePassword(filter);
    
    console.log("Change Password: ",pdata);

    if(pdata.success === true){
        alert("Password  updated successfully.")
        this.setState({
            username: "",
            password: "",
            newpassword: "",
            newpassword2: "",
            email: ""
        });
    }
}



render() {
    return (
      <div className='changepass'>
            <div className="top">
                <h1>Change Password</h1>
            </div>
            <div className="bottom">
                <form>
                    <div className="form-group">
                        <label htmlFor="cp-user"><b>User ID</b></label>
                        <input type="text" className="form-control" onChange={this.handleChange} 
                            id="cp-userid-txt" placeholder="User ID" aria-label="user id" 
                            aria-describedby="cp-userid-txt" value={this.state.username}/>
                    
                    </div>

                    <div className="form-group">
                        <label htmlFor="cp-email"><b>Email</b></label>
                            <input type="email" className="form-control" onChange={this.handleChange} id="cp-email-btn"  
                                placeholder="E-mail" aria-label="email address" aria-describedby="cp-email-btn"
                                value={this.state.email}/>
                    
                    </div>
                    <div className="form-group">
                        <label htmlFor="cp-pawwwd-old"><b>Old Password</b></label>
                        <input type="password" onChange={this.handleChange} className="form-control" id="cp-pawwwd-old" 
                            placeholder="Password" value={this.state.password}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cp-pawwwd-1"><b>New Password</b></label>
                        <input type="password" onChange={this.handleChange} className="form-control" 
                            id="cp-pawwwd-1" placeholder="Confirm Password" value={this.state.newpassword}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cp-pawwwd-2"><b>New Password</b></label>
                        <input type="password" className="form-control" onChange={this.handleChange} 
                            id="cp-pawwwd-2" placeholder="Confirm Password" value={this.state.newpassword2}/>
                    </div>
                    
                    <button type="submit" onClick={this.handleSubmit} className="btn btn-primary my-3">Change Password</button>
                </form>

            </div>

                
                

        </div>
        
    )
  }
}
