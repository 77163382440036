import React, { Component } from 'react'

import Navbar from '../../components/navbar/Navbar'
export default class About extends Component {
  render() {
    return (
      <div className='about'>
        <div className="homecontainer">
          <Navbar></Navbar>
          <div>
            <h1>About Us</h1>
          </div>
          
        </div>
      </div>
    )
  }
}
