import React, { Component } from 'react'
import "./footer.scss"
export default class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <p>© 2022. All Rights Reserved.</p>
      </div>
    )
  }
}
