import React, { Component } from 'react'

export default class Searchsummary extends Component {

    constructor(){
        super();
        this.state={
            filter: null,
            dirty: false,
            searchsummary: null,
            skip:0
        }

        this.renderTableRow=this.renderTableRow.bind(this);
        this.handleRow=this.handleRow.bind(this);

    }



    handleRow(e){
        console.log(e.target.id)
        if(e.target.id !==undefined){
            var sp=e.target.id.split('#');
            if(sp.length>1){
                console.log("sp[1]",sp[1])
                this.props.handleq(sp[1])
            }
    
        }
    }
  
    renderTableRow(basequest) {

        if(basequest === null || basequest.length <=0){
            return (<tr><th scope="row">{"No data found"}</th><td>{"..."}</td><td>{"..."}</td></tr>);
        } else {
            return(
                basequest.map((item,index)=>{
                    return (<tr  key={item._id + "tbl" + index} id={item._id} onClick={this.handleRow}>
                        <th scope="row" id={"rowsn#"+item._id}>{index}</th>
                        <td id={"rowid#"+item._id}>{item._id}</td>
                        <td id={"rowtitle"+item._id}>{item.title}</td>
                        </tr>);
                })
                )
        }
        
    }

  render() {


    const basequest=this.props.data;

    if (basequest === null || basequest === undefined){
        return (<><h3>No Data found</h3></>)
    }
        
    //console.log("Searchsummary: Start rendering: ", basequest, basequest.length);


    return (
      <div>
        <div className="table-responsive custom-table" >
        <div className="table-responsive">
            <table className="table table-hover">
                <thead className="thead-dark">
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Description</th>
                    <th scope="col">More ...</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderTableRow(basequest)}
                    
                </tbody>
            </table>
              
            </div>
        </div>    
  
      </div>
    )
  }
}
