import React, { Component } from 'react'
import FormComSubjects from '../FormComSubject'

import DataAPI from '../../api/DataAPI';
import "./questioncom.scss"
import {reason_list} from "../../data/data"
import Auth, {axiosInstance} from '../../context/Auth';
var Latex = require("react-latex");

/*
[{
   title:  {type: String, unique : true, required : true},
  parent_id: {type: Number}, 
  subjects:   [String],
  topics:   [String],
  tags:   [String],
  complex: {type: Number},
  recommended_by: {type: String},
  institute: {type: String},
  options:[{
    desc: String,
    valid: Boolean 
  }],
  date: { type: Date, default: Date.now },

  Example:
[{
  "title":  "What do you mean by law of conservation of momentum",
  "parent_id": 0, 
  "subjects":   ["physics"],
  "topics":   ["laws of motion"],
  "tags":   ["motion"],
  "complex": 2,
  "recommended_by": "Gaurav",
  "institute": "My School",
  "options":[{
    "name": "Its a nature of law",
    "valid": 0
  },{
    "name": "Its a fake of law",
    "valid": 0
  }]
}]

*/

export default class Questioncom extends Component {

    topics=[]
    selectedtopic=[]
    options=[
        {"id":0,"name":"",valid:0,"maths":false, "reason":"CAL"},
        {"id":1,"name":"",valid:0,"maths":false, "reason":"CAL"},
        {"id":2,"name":"",valid:0,"maths":false, "reason":"CAL"},
        {"id":3,"name":"",valid:0,"maths":false, "reason":"CAL"},
        {"id":4,"name":"",valid:0,"maths":false, "reason":"CAL"},
        {"id":5,"name":"",valid:0,"maths":false, "reason":"CAL"},
        {"id":6,"name":"",valid:0,"maths":false, "reason":"CAL"},
        {"id":7,"name":"",valid:0,"maths":false, "reason":"CAL"},
    ]
    constructor(){
        super();
        let email = new Auth().getEMail();
        let className = new Auth().getClass();

        this.getSubject = this.getSubject.bind(this);
        this.handleCreatorOnChange = this.handleCreatorOnChange.bind(this);
        this.handleComplexOnChange = this.handleComplexOnChange.bind(this);
        this.handlePIDOnChange = this.handlePIDOnChange.bind(this);
        this.handleQOnChange = this.handleQOnChange.bind(this);
        this.handleTagOnChange = this.handleTagOnChange.bind(this);
        this.postTopicData = this.postTopicData.bind(this);
        this.setOptionsData = this.setOptionsData.bind(this);
        this.handleTopicSelect = this.handleTopicSelect.bind(this);
        this.handleImageOnChange=this.handleImageOnChange.bind(this);
        this.handleMath=this.handleMath.bind(this);
    
        this.state={
            subject:"all",
            topics:this.topics  ,
            topicSubjects: [],
            selectedtopic: this.selectedtopic,
            searchsummary:[],
            title:"",
            parentid: 0,
            tags:className,
            complex: 2,
            recommended_by:email,    
            image: [],        
            institute:"",
            options:this.options,
            seltopicindex:0,
            maths:false,
            loading: false
        };

    }

    setOptionsData(e){
        let id = e.target.id;
        //console.log("Option cliecked: ", id) 
        //console.log("Option cliecked: ", e.target.checked) 
        let ids=id.split('-');
        let ind=Number(ids[1])-1
        console.log("option: ", ind,this.options[ind]) 
        if(ids[0] === 'staticopttext'){
            this.options[ind].name=e.target.value
        } else if (ids[0] === 'staticopt'){
            this.options[ind].valid=e.target.checked
        } else if (ids[0] === 'emathopt'){
            this.options[ind].maths=e.target.checked
        } else if(ids[0] === 'optreason'){
            this.options[ind].reason=e.target.value
        }
        this.setState({
            options: this.options
        });
        console.log(this.options)

    }

    async getSubject(sdata)
    {
        console.log("SName: ",sdata);
        this.componentDidMount1(sdata);
    } 

    async componentDidMount1(sdata){
        if(!sdata){
            sdata="all"
        }

        if(sdata){
            
            new DataAPI().getTopics(sdata, this,function(obj, pdata){    
            if(pdata.success === true){
            //this.state.;
            obj.setState({
                topics: pdata.data[0],
                topicSubjects: pdata.data[1],
                seltopicindex:0,
                subject: sdata,
                loading: true
            })
            }else{
                alert(pdata.data.data);
            }
        });
        }
    }


    async handleTopicSelect(e){
        //console.log("Topic selected ----------------", e.target.value)
        let tindex=this.state.topics.indexOf(e.target.value);
        

        const filter = {
            subjects: this.state.subject,
            topics: [e.target.value],
            parentid:"0"
        }

        //console.log("filter: ",filter);
        new DataAPI().getBaseQuest(filter, this, function (obj, data){
        //console.log(data)

        if(data.success === true){
            if (data.data.length >0){
            obj.setState({
                searchsummary:data.data,
                selectedtopic:[e.target.value],
                seltopicindex: tindex
                })} else {
                obj.setState({
                    selectedtopic:[e.target.value],
                    seltopicindex: tindex,
                    searchsummary:[]
                })                
            }
            console.log("this.state.seltopicindex: ", tindex, obj.state.topicSubjects[tindex])
        } 
        });
        
    }

    handleTagOnChange(e){
        console.log(e.target.value)
        this.setState({
         tags:e.target.value.replace(/\s/g, "")
        });
    }


    handleQOnChange(e){
        console.log(e.target.value)
        if(e.target.id === "qf-statictitle"){
            this.setState({
                title: e.target.value,
                desc:  e.target.value
            });
    
        } else if(e.target.id === "qf-staticdesc"){
            this.setState({
                desc:  e.target.value
            });

        }
    }

    handlePIDOnChange(e){
        console.log(e.target.value)
        this.setState({
            parentid: e.target.value
        });
    }

    handleComplexOnChange(e){
        console.log(e.target.value)
        this.setState({
            complex: e.target.value
        });
    }
    
     handleCreatorOnChange(e){
        console.log(e.target.value)
        this.setState({
            recommended_by: e.target.value
        })
        
    }

    handleImageOnChange(e){
        if(e.target){
            console.log("IMAGE LIST: ",e.target.value)
        if(e.target.value.length > 0){
            const imagelist=e.target.value.split(',')
            this.setState({
                image: imagelist
            })
        }
        } else {
            console.log("handleImageOnChange: ", e.target)
        }
    }

    postTopicData(e){
        e.preventDefault();
        const postdata=[{
            "title": this.state.title,
            "desc": this.state.desc,
            "subjects": this.state.topicSubjects[this.state.seltopicindex],
            "tags": this.state.tags.split(','),
            "parentid": this.state.parentid,
            "topics": this.state.selectedtopic,
            "complex":this.state.complex,
            "recommended_by": this.state.recommended_by,
            "image":this.state.image,
            "institute":this.state.institute,
            "options":this.state.options,
            "maths":this.state.maths
          }]        
          let tock = new Auth().getToken();  
          //console.log("Option value before sending: ", postdata[0].options)
          axiosInstance.post('/questroute/quest', 
                        postdata,
                        {
                            headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                            'auth-token': tock}
                        }).then(
                            response=>{
                                console.log(response);
                                if(response.data.success === true){
                                    var options=[
                                        {"id":0,"name":"",valid:0,"maths":false, "reason":"CAL"},
                                        {"id":1,"name":"",valid:0,"maths":false, "reason":"CAL"},
                                        {"id":2,"name":"",valid:0,"maths":false, "reason":"CAL"},
                                        {"id":3,"name":"",valid:0,"maths":false, "reason":"CAL"},
                                        {"id":4,"name":"",valid:0,"maths":false, "reason":"CAL"},
                                        {"id":5,"name":"",valid:0,"maths":false, "reason":"CAL"},
                                        {"id":6,"name":"",valid:0,"maths":false, "reason":"CAL"},
                                        {"id":7,"name":"",valid:0,"maths":false, "reason":"CAL"},
                                    ]
                                    alert("question submitted successfully");
                                    this.setState({
                                        subject:"all",
                                        topics:this.topics  ,
                                        selectedtopic: this.selectedtopic,
                                        title:"",
                                        parentid: 0,
                                        tags:new Auth().getClass(),
                                        complex: 2,
                                        image: [],        
                                        institute:"",
                                        options:options,
                                        loading: false
                                    })
                                    }                                
                            }
                        ).catch(error=>{
                            console.log(error)
                        }
                    )


    }

  insertOption(ind){

    const latstr=`$$${this.options[ind-1].name}$$`
    return (
            <div className="option">
                
                <label htmlFor={"staticopt-"+ind} className="form-label">Option-{ind}</label>
                <div className="input-group-text">
                        M<input className="form-check-input" type="checkbox" onChange={this.setOptionsData} id={"emathopt-"+ind} aria-label="Enable Maths"/>
                        A<input className="form-check-input" type="checkbox" onChange={this.setOptionsData} id={"staticopt-"+ind} aria-label="Checkbox for following text input"/>
                </div>
                <input type="text" id={"staticopttext-"+ind} onChange={this.setOptionsData} className="form-control" aria-label="Text input with checkbox"/> 
                    {(this.options[ind-1].maths===true)?<Latex>{latstr}</Latex>:<></>}
                <select id={"optreason-"+ind} className="form-select" aria-label="Select Type" defaultValue={'CAL'} onChange={this.setOptionsData}>
                    <option key={"reason-fcs-1"} value="">Select Reason</option> 
                    {
                        reason_list.map((q)=><option key={q.id} value={q.title}>{q.desc}</option>)
                    }
                </select>                      
                    
            </div>
    )
  }


  handleMath(e)
  {
    if(this.state.maths === false){
        this.setState({maths:true});
    } else {
        this.setState({maths:false});
    }
        
  }

  render() {

    if(this.state.loading === false){
        //console.log("Reloading componentDidMount1")
        this.componentDidMount1();
        return;
    }
    //console.log("Formquest Render: ", this.state.topics, " loading: ",this.setOptionsData.loading);
    //console.log(this.state.searchsummary)
    //const topicList = this.state.topics.map((note) => <li className="list-group-item">{note}</li> );
    const topicList = this.state.topics.map((note) => <option key={note +"fcs-1"} value={note}>{note}</option> );
    const parentList=this.state.searchsummary.map((note) => <option key={note._id} value={note._id}>{note.title}</option> );
    
    return (
      <div className='questioncom'>

        <div className="top">
            <h1>Write New Questions</h1>
        </div>
        <div className="middle">
            <form>
                <div className="form-control">
                <FormComSubjects title="subject" multisel={false} datafunc={this.getSubject}/>
                </div>

                <div className="form-control">
                    <select className="form-select" aria-label="Select Subject" defaultValue={'None'} onChange={this.handleTopicSelect}>
                        <option key={"default-fcs-1"} value="">Select Topic</option> 
                            {topicList}
                    </select>
                </div>

                <div className="form-control">
                    <label htmlFor="statictitle" className="form-label">Base Question</label>
                    <input type="text" className="form-control" onChange={this.handleQOnChange} id="qf-statictitle"  placeholder="Write basic question here"/>
                </div>
                <div className="form-control">
                    <label htmlFor="staticdesc" className="form-label">Question Description</label>
                    <input type="text" className="form-control" onChange={this.handleQOnChange} id="qf-staticdesc"  placeholder="Write question description here" 
                    value={this.state.desc}/>
                </div>
                <div className="form-control">
                    <label htmlFor="statictag" className="form-label">Tags</label>
                    <input type="text" className="form-control" onChange={this.handleTagOnChange} id="qf-statictag"  placeholder="Comma seperated tags" value={this.state.tags}/>
                </div>

                {/* <div className="form-control">
                <label htmlFor="statictag" className="form-label">Upload Images</label>
                    <Uploadimage/>
                </div> */}

                <div className="form-control">
                    <label htmlFor="staticimagecon" className="form-label">Images</label>
                    <input type="text" className="form-control" onChange={this.handleImageOnChange} id="qf-staticimagecon"  placeholder="Image Name"/>
                </div>

                <div className="form-control-check">
                    <label className="form-check-label" >Enable Maths</label>
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckMathenable" onChange={this.handleMath}/>
                </div>
            </form>

        </div>
        <div className="bottom">
            <div className="left">
                <div className="options">
                <p>M: Maths, A: Correct Answer</p>
                    {this.insertOption(1)}
                    {this.insertOption(3)}
                    {this.insertOption(5)}
                    {this.insertOption(7)}
                </div>
            </div>
            <div className="right">
                <div className="options">
                    {this.insertOption(2)}
                    {this.insertOption(4)}
                    {this.insertOption(6)}
                    {this.insertOption(8)}
                </div>
            </div>
        </div>
        <div className="bottom2">
            <form>
                <div className="form-control">
                    <label className="form-label">Base Question</label>
                    <select className="form-select" aria-label="Select Parent" defaultValue={'None'} onChange={this.handlePIDOnChange} >
                    <option key="0000" value="">Select related base question</option>
                        {parentList}
                    </select>            
                </div>

                <div className="form-control">
                    <label htmlFor="staticcomplex" className="col-sm-1 col-form-label">Complexity</label>
                    <input type="text" className="form-control" onChange={this.handleComplexOnChange} id="qf-staticcomplex" placeholder="1-5 i.e  1- low, 5- high"/>
                </div>

                <div className="form-control">
                    <label htmlFor="staticcreator" className="col-sm-1 col-form-label">Created By</label>
                    <input type="text" className="form-control" onChange={this.handleCreatorOnChange} id="qf-staticcreator" 
                        value={this.state.recommended_by}
                        placeholder={this.recommended_by} />
                </div>

                <button onClick={this.postTopicData} className="button">Submit</button>

            </form>
        </div>
        <div className='message'>
                <h1>WORK WISELY</h1>
            </div>                 
      </div>


    )
  }
    
}

