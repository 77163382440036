import React, { useRef } from 'react'

import {Link, useNavigate} from "react-router-dom"
import './sidebar.scss'
//import { Transition } from 'react-transition-group'
import Auth from '../../context/Auth';

export default function Sidebar(props) {
  const navigate = useNavigate();
  //const [hide, setHide]=useState(false);
  //let hide=false
  let sbarref = useRef();
    // if(props.isSbarActive !== undefined){
    //   //hide=props.isSbarActive;
    //   setHide(props.isSbarActive)
    // }
   
    // useEffect(()=>{
    //   document.addEventListener("mousedown",(event)=>{
    //     console.log("AA document.addEventListener: ",event.target.id);
    //       if(!sbarref.current.contains(event.target)){
    //         props.sstate(true);
    //       }
    //   })
    // });  
  

    function handleOnHide(){
      props.toggleSidebar();
    }


    function handleLogout()
    {

      new Auth().logOut();
      navigate("/home")
    }

    //console.log("props.isSbarActive", props.isSbarActive)
    var cname=new Auth().getClass();
    return (
      <div ref={sbarref} className='sidebar' hidden={props.isSbarActive} style={{"transition": "5s, ease"}}>
        <div className='top'>
        <Link to="/" >
          <span className="logo"><img src="./logo512.png" alt=""/></span>
          </Link>
          <div className="closeicon" onClick={handleOnHide}> <span className="material-symbols-outlined">
            chevron_left
          </span>
</div>
        </div>

        
        <hr/>

        <div className='center'>
          <ul>
          <p className='title_user'>STUDENT - {cname}</p> 
          <hr></hr> 
          <p className='title'>MAIN</p>
            <Link to="/user/dashboard" className='Dashboard' 
            style={{ textDecoration: 'none' , color: 'inherit'}}>
            <li >
              <span className="material-symbols-outlined icon">monitoring</span>
              <span className=''>Dashboard </span>
            </li>
            </Link>

            <Link to="/user/preptest" className='Prepare' style={{ textDecoration: 'none' , color: 'inherit'}}>
            <li>
            <span className="material-symbols-outlined icon">description</span>
              <span>Prepare</span>
            </li>
              </Link>

            <Link to="/user/starttest" className='starttest' style={{ textDecoration: 'none' , color: 'inherit'}}>
              <li>
                <span className="material-symbols-outlined icon">start</span>
                <span>Start</span>
              </li>
            </Link>
            
            <Link to="/user/testhist" className='starttest' style={{ textDecoration: 'none' , color: 'inherit'}}>
              <li>
                <span className="material-symbols-outlined icon">History</span>
                <span>History</span>
              </li>
            </Link>
            <Link to="/user/oldtest" className='starttest' style={{ textDecoration: 'none' , color: 'inherit'}}>
              <li>
                <span className="material-symbols-outlined icon">History</span>
                <span>Attempted List</span>
              </li>
            </Link>
            <Link to="/user/profile" className='starttest' style={{ textDecoration: 'none' , color: 'inherit'}}>
            <li>
            <span className="material-symbols-outlined icon">history_edu</span>
              <span>Profile</span>
            </li>
            </Link>
            
            <li>
            <span className="material-symbols-outlined icon">notifications</span>
              <span>Notifications</span>
            </li>
            <li >
                <span className="material-symbols-outlined icon">settings</span>
                  <span>Settings</span>
              </li>
 

              <p className='title'>USER</p>
              <Link to="/forget" className='chpass' style={{ textDecoration: 'none' , color: 'inherit'}}>
              <li >
                <span className="material-symbols-outlined icon">settings_accessibility</span>
                  <span>Change Password</span>
              </li>
              </Link> 
              <li onClick={handleLogout}>
              <span className="material-symbols-outlined icon" >logout</span>
                  <span>Logout</span>
              </li>
          </ul>
        </div>

        
        
        <div className='bottom'>

                  <div className="coloroption"></div>
                  <div className="coloroption"></div>
                  <div className="coloroption"></div>
                  <div className="coloroption"></div>
        </div>
      </div>
    )
  }

