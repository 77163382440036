import React, { Component } from 'react'
import Auth from '../../context/Auth';
import DataAPI from '../../api/DataAPI';
//import {study_comment} from '../../data/data'
import "./chart.scss"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="desc">{`${label}`}</p>
        <hr></hr>
        <p className="desc">Calculations: {`${payload[0].payload.CAL}`}</p>
        <p className="desc">Conceptual: {`${payload[0].payload.CON}`}</p>
        <p className="desc">Recalling: {`${payload[0].payload.REC}`}</p>
        <p className="desc">Reading: {`${payload[0].payload.RED}`}</p>
        <p className="desc">Concentration: {`${payload[0].payload.COT}`}</p>
        <p className="desc">Misc: {`${payload[0].payload.MIS}`}</p>
      </div>
    );
  }

  return null;
};

export default class Topicgreychart extends Component {


  constructor(){
    super();
    let auth = new Auth();
    this.state={
      email:auth.getEMail(),
      data:[],
      show:true
    }

  }

   // static demoUrl = 'https://codesandbox.io/s/stacked-area-chart-ix341';
   processData(d)
   {
    //console.log("processData $$$$$$$$$$ Data Received", d)
     this.setState({data:d, show:false})
   }
   componentDidMount()
   {
    if(this.state.show === false) return;

    var filter={
      email: this.state.email,
      subject:this.props.subject
    }
    new DataAPI().getTopicGreytData(filter, this);
   }

  render() {
    var size=50;

    if (this.state.data.length > 10){
      size=Math.ceil(500/this.state.data.length);
    }    
    return (
      <div className='chart'>
        <h3>{this.props.subject}</h3>
        <ResponsiveContainer width="100%" aspect={3/1}>
        <BarChart
          width={500}
          height={400}
          barSize={size}
          data={this.state.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip data={this.state.data}/>}/>
          <Bar type="monotone" dataKey="CAL" stackId="1" stroke='rgba(255, 99, 132, 1)' fill='rgba(255, 99, 132, 0.5)'/>
          <Bar type="monotone" dataKey="CON" stackId="1" stroke='rgba(54, 162, 235, 1)' fill='rgba(54, 162, 235, 0.5)' />
          <Bar type="monotone" dataKey="RED" stackId="1" stroke='rgba(255, 206, 86, 1)' fill='rgba(255, 206, 86, 0.5)' />
          <Bar type="monotone" dataKey="REC" stackId="1" stroke='rgba(75, 192, 192, 1)' fill='rgba(75, 192, 192, 0.5)' />
          <Bar type="monotone" dataKey="COT" stackId="1" stroke='rgba(153, 102, 255, 1)' fill='rgba(153,102,255, 0.5)' />
          <Bar type="monotone" dataKey="MIS" stackId="1" stroke='rgba(255, 159, 64, 1)' fill='rgba(255, 159, 64, 0.5)' />
          {/* <Bar type="monotone" dataKey="total" stackId="0" stroke="#ffc658" fill="#ffc658" /> */}
        </BarChart>
      </ResponsiveContainer>
      </div>
    )
  }
}
