import React from 'react'

import "./productcom.scss";
export default function Productcom(props) {
  return (
        <li className={props.active?"productcom active":"productcom"} 
            onClick={()=>{props.setSelected(props.id)}}>
            {props.title}
        </li>
  )
}


// import "./productcom.scss";
// export default function Productcom(id, title, active, setselected) {
//   return (
//         <li className=>
//             {title}
//         </li>
//   )
// }
