import React from 'react'
import DataAPI from '../../api/DataAPI'
import "./contactus.scss"

export default function ContactUs() {

   const onsendEmail = async (e) =>{
    const { subject, email, message } = e.target.elements
    try{
    let conFom = {
      subject: subject.value.trim(),
      email: email.value.trim(),
      message: message.value.trim(),
    }

    new DataAPI().sendMessage(conFom,this,(resData)=>{
      if(resData.success === true){
        alert("Message sent successfully. ")
      } else  {
        alert("We could not process your message. Please try again after some time.")
      }
    });
    //alert("We could not process your message. Please try again after some time.");
  }catch(err){
    alert("We could not process your message. Please try again after some time.");
  }
  }




  return (
    <div className='contactus' id="contactus">
      <div className="left">
        <img src='assets/handshake_640.png' alt=''/>
      </div>
      <div className="right">
        <h2>Contact Us</h2>
        <form onSubmit={onsendEmail}>
          <input type='email' placeholder='Your E-Mail' id="email"/>
          <input type='text' placeholder='Subject' id="subject"/>
          <textarea placeholder='Write your message here' id="message"></textarea>
          <button type='submit'>Send</button>
        </form>
      </div>
    </div>
  )
}
