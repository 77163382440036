import React, { useEffect, useState } from 'react'
import Productcom from '../products/Productcom'
import { productlist,eduapp,mobapp, wip } from '../../data/data'
import "./products.scss"

export default function Products() {
  const [selected,setSelected]=useState('edu-app');
  const [data,setData]=useState(['edu-app']);
  //const data = eduapp;


  useEffect(()=>{
    switch(selected){
      case 'edu-app':
        setData(eduapp);
        break;
      case 'mob-apps':
        setData(mobapp);
        break;
      default:
        setData(wip);
        break;

    }
  },[selected])

  return (
    <div className='products' id="products">
      <h1>Products/Platform</h1>
      <ul>
      {productlist.map(d=>(
        <Productcom id= {d.id} title={d.title}  active={selected === d.id} setSelected={setSelected}/>
        ))}
      </ul>
      <div className="container">

        {data.map(d=>(
        <div className="item">
          <img src={d.img} alt=""/>
          <h3>{d.title}</h3>
        </div>

        ))}
      </div>
    </div>
  )
}
