import React, { useState } from 'react'
import { templates } from '../../data/data';
import "./intro.scss"
export default function Intro(props) {
  // const [currentSlider, setCurrentSlider] = useState(0);
  // const [timerTimeout, setTimerTimeout] = useState(0);

  const [counter, setCurrentCounter] = useState(0);
  const handleViewClick = ()=>{
    var val = Math.floor(Math.random() * 5);//counter;
    if(val > 5) val=0;
    setCurrentCounter(val);
    
  }
  // const handleClick = ()=>{
  //   console.log('handleClick----------------------!')
  //   clearTimeout(timerTimeout)
  //   setTimerTimeout(0);
  //    setCurrentSlider(currentSlider<3?currentSlider+1:0);
  // }

  // const handleClickLeft = () =>{
  //   console.log('handleClickLeft----------------------!')
  //   clearTimeout(timerTimeout)
  //   setTimerTimeout(0);
  //   setCurrentSlider((currentSlider>0)?currentSlider-1:3);
  // }

  var col1 =templates[counter].webMain1;
  var col2 =templates[counter].webMain2;
  var col3 =templates[counter].webMain3;
  var col4 =templates[counter].webMain4;
  
  return (

    <div className='intro' id="intro" onClick={handleViewClick} >
        <div className="circlebig"  style={{top:"50%", left:"50%"}}>
          <div className="back">
            Preparation
          </div>
          
        </div>   
        {/* <div className="circlebiglight " style={{top:"-30%", left:"75%",fontSize:"20px"}}></div>       
        <div className="circlebiglight " style={{top:"-30%", left:"25%",fontSize:"20px"}}></div>       
        <div className="circlebiglight " style={{top:"-30%", left:"50%",fontSize:"20px"}}></div>       
        <div className="circlebiglight " style={{top:"-30%", left:"0%",fontSize:"20px"}}></div>        */}
        <div className="circlebig1 " style={{background: col1,top:"-10%", left:"25%",fontSize:"20px"}}>
        <span style={{background: "black", color: "white" }}>Evaluations</span>
        </div>        
        <div className="circlesmall " style={{background: col2,top:"40%", left:"12%",fontSize:"20px"}}>
          TEST
        </div>        
        <div  className="circlesmal2" style={{background: col3,top:"50%", left:"75%", fontSize:"30px"}}>
          CBSE
        </div>        
        <div  className="circlesmal4" style={{background: col4,top:"-30%", left:"-5%",fontSize:"50px"}}>
          Guidance
        </div> 
        <div  className="circlesmal3" style={{background: col2,top:"-20%", left:"75%"}}>
        <span style={{ color: "white"}}>Board Examinations</span>
        </div> 
        <div  className="digit" style={{top:"-20%", left:"15%"}}>
        <span style={{ color: "white"}}>Digitization</span>
        </div> 
        <div  className="virtual" style={{background: col1,top:"0%", left:"55%",fontSize:"60px"}}>
        <span style={{background: "black", color: "white"}}>Virtualization</span>
        </div> 
        <div  className="virtual" style={{top:"-10%", left:"-15%"}}>
        <span style={{background: "black", color: "white"}}>Performance and Planning</span>
        </div>  
        <div  className="planning" style={{background: col3,top:"-50%", left:"41%"}}>
        <span style={{color: "black",fontSize:"60px"}}>Resource & Efforts Optimization</span>
        </div>    
        <div  className="Recognition" style={{top:"20%", left:"0%"}}>
        <span style={{background: "black", color: "white"}}>Recognition & Rewarding</span>
        </div>                      
    </div>
  )
}

