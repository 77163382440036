import React, { Component } from 'react'
import FormComSubjects from '../FormComSubject'
import Searchsummary from './Searchsummary';
import Updatequest from './Updatequest';
import DataAPI from '../../api/DataAPI';

import "./qsearchcom.scss"

export default class Qsearchcom extends Component {

    topics=[]


    constructor(){
        super();
        this.renderSearchForm=this.renderSearchForm.bind(this);
        this.renderSearchSummary=this.renderSearchSummary.bind(this);


        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.handleQOnChange = this.handleQOnChange.bind(this);
        this.handleTagOnChange = this.handleTagOnChange.bind(this);
        this.onChangeTopic = this.onChangeTopic.bind(this);
        this.onClickApply=this.onClickApply.bind(this);
        this.renderTableRow=this.renderTableRow.bind(this);
        this.handleQuest=this.handleQuest.bind(this);
        this.handleNext=this.handleNext.bind(this);
        this.handlePrev=this.handlePrev.bind(this);
         
         
        this.state={
            subjects: [],
            topics: [],
            tags:[],
            basequest:[],
            topicSubjects: [],
            searchsummary:undefined,
            selectedtopic: undefined,
            key: undefined,
            skip:0,
            loading: true            
        };

    }

    handleNext(e){
        this.setState({
            skip: this.state.skip+10
        });

    }
    handlePrev(e){
        var skip=this.state.skip-10;
        if (skip <0){
            skip=0
        }
        this.setState({
            skip: skip
        });

    }
    async onClickApply(e){
        //console.log("-------------------------------onClickApply: ", e.target.id);

        // console.log("Filter: parent id", this.state.key)
        // console.log("Filter: Topic", this.state.selectedtopic)
        // console.log("Filter: subject", this.state.subjects)
        // console.log("Filter: tag", this.state.tags)

        if(e.target.id === "fqs-topic"){
            const filter = {
                subjects: this.state.subjects,
                topics: this.state.selectedtopic,
                skip: this.state.skip
            }

            new DataAPI().getBaseQuest(filter, this, function (obj, data){

            

            if(data.success === true){
                //console.log("onClickApply data.data.length", data.data.length)
                if (data.data.length >0){
                    obj.setState({
                    searchsummary:data.data,
                    skip: obj.state.skip+data.data.length
                })} else {
                    obj.setState({
                        searchsummary:undefined
                    })                
                }
            } 
            });
        } else if(e.target.id === "fqs-tag"){
            const filter = {
                parentid:undefined,
                subjects:this.state.subjects,
                topics: this.state.selectedtopic,
                tags:undefined,
                skip: this.state.skip
            }
            //console.log(this.state.key)
            if (this.state.key !== undefined ){
                //console.log("Key is not undefined")
                filter.parentid= this.state.key;
            } else {
                // console.log("Key is undefined")
                // console.log(this.state);
                if(this.state.subjects.length > 0){
                    filter.subjects= this.state.subjects;
                }
    
                if(this.state.selectedtopic !== undefined && this.state.selectedtopic.length > 0){
                    //console.log("selectedtopic: ",this.state.selectedtopic);
                    filter.topics= this.state.selectedtopic;
                }
            }
            //console.log(this.state.tags)
            if(this.state.tags !== undefined && this.state.tags.length > 0){
                filter.tags= this.state.tags;
            } else {
                //console.log("Empty tag array")
                return;
            }


            console.log("Tags Filter: ", filter);
            const data = new DataAPI().getBaseQuest(filter, this, function (obj, data){
                //console.log(data);
                //console.log("Data receied from api: ", data);
                var len=data.data.length;
                obj.setState({
                    searchsummary: data.data,
                    skip: obj.state.skip + len
                })             
            });
        } else if(e.target.id ==="fqs-quest"){
            if (this.state.key === undefined) {
                console.log("Key is undefined")
                return;
            }
            const filter={
                parentid: this.state.key,
                skip: this.state.skip
            }
    
            let data = new DataAPI().getChildQuest(filter,this, function(obj, data){
                //console.log("Data receied from api: ", data);
                this.setState({
                    searchsummary: data.data,
                    skip: this.state.skip+data.data.length
                })            
            })
        }
    }


    onChangeSubject(sdata)
    {
        console.log("SName: ",sdata)
        if(sdata===""){
            this.setState({
                topics: [],
                topicSubjects: [],
                subjects: [],
                loading: true,
                key: undefined,
                basequest: [],
                searchsummary:undefined,
                selectedtopic: undefined,   
                skip:0                 
            })        
        }

        if(sdata){
            const filter={
                subjects: [sdata]
            }
            new DataAPI().getTopicList(filter, this, function(obj, resData){
                if(resData.success === true){
                    obj.setState({
                        topics: resData.data[0],
                        topicSubjects: resData.data[1],
                        subjects: [sdata],
                        loading: true,
                        key: undefined,
                        basequest: [],
                        searchsummary:undefined,
                        selectedtopic: undefined,    
                        skip:0                 
                    })
    
                    console.log(obj.state)
                    }else{
                        console.log(resData);
                    }
            });

                
            }
    }


    onChangeTopic(e){
        //console.log("Topic selected ----------------", e.target.value)
        if (e.target.value === "None") {
            this.setState({
                selectedtopic: undefined
            })            
            return;
        }
        //let tindex=this.state.topics.indexOf(e.target.value);

        //console.log("Selected index: ", tindex, this.state.topicSubjects[tindex])

        const filter = {
            subjects: this.state.subjects,
            topics: [e.target.value],
        };

        //console.log("Data receied from api: ", filter);
        //const dataApi = new DataAPI()
        new DataAPI().getBaseQuest(filter, this, function (obj, data){
        

        if(data.success === true){
            if (data.data.length >0){
            obj.setState({
                basequest: data.data,
                searchsummary:data.data,
                selectedtopic: [e.target.value]
            })} else {
                obj.setState({
                    basequest: [],
                    searchsummary:undefined,
                    selectedtopic: [e.target.value],
                    key: undefined
                })                
            }
        } 
    });

    }


    handleTagOnChange(e){
        //console.log("------------------------- handleTagOnChange: ",e.target.value)
        const arr=e.target.value.toUpperCase().split(',')
        console.log(arr);
        if(arr.length>0){



            this.setState({
                tags:arr
            });

    }   else {
            console.log("empty tags")
        }
        
    }


    handleQOnChange(e){
        const select = e.target;
        const sid = select.children[select.selectedIndex].id;
        //console.log("------------- handleQOnChange: ->", sid, e.target.value)

        if (e.target.value ==='select-par-pqst-fqs')
        {
            this.setState({
                key:undefined
            })
        }

        if (e.target.value === "None") {
            this.setState({
                key:'None'
            })
            return;
        }
        

        const filter={
            parentid: sid
        }

        new DataAPI().getChildQuest(filter,this, (obj, data)=>{
            //console.log("Data receied from api: ", data);
            obj.setState({
                searchsummary: data.data,
                key: sid
            })
        })
        
    }

    handleQuest(r)
    {
        this.setState({
            key: r
        })
    }

    renderTableRow() {
        if(this.state.searchsummary === undefined || this.state.searchsummary.length <=0){
            return (<tr><th scope="row">{"No data found"}</th><td>{"..."}</td><td>{"..."}</td></tr>);
        } else {
            return(
                this.state.searchsummary.map((item,index)=>{
                    return (<tr  key={item._id + "tbl" + index}><th scope="row">{index}</th><td>{item.title}</td><td>{item._id}</td></tr>);
                })
                )
        }
    }


    renderSearchForm(topicList,baseQuestList){
        return(
            
                <form>
                        <div className="input-group">
                        <h2>Search form</h2>
                        </div>
                        <FormComSubjects title="subject" multisel={false} datafunc={this.onChangeSubject} id="fqs-subcomp"/>

                        <div className="input-group">
                            <label htmlFor="qsftopics" className="form-label">Topics</label>
                            <select className="form-select" aria-label="Select Subject" defaultValue={'None'} onChange={this.onChangeTopic} onClick={this.onChangeTopic}>
                                <option key={"select-top-fqs"} value="None">Select Topic</option>
                                {topicList}
                            </select>
                            <button className="button" type="button" onClick={this.onClickApply} id={"fqs-topic"}>Apply</button>
                        </div>    

                        <div className="input-group">
                            <label htmlFor="statictitle" className="form-label">Question</label>
                            <select className="form-select" aria-label="Select Base Question" defaultValue={'None'} onChange={this.handleQOnChange} onClick={this.handleQOnChange}>
                            <option key={"select-par-pqst-fqs"} value="select-par-pqst-fqs">Select Parent Question</option>
                                {baseQuestList}
                            </select>
                            <button className="button" type="button"  onClick={this.onClickApply} id={"fqs-quest"}>Apply</button>
                        </div>

                        <div className="input-group">
                            <label htmlFor="statictag" className="form-label">Tags</label>
                            <input type="text" className="form-control" onChange={this.handleTagOnChange} id="qsf-statictag"  placeholder="Comma seperated tags"/>
                            <button className="button" type="button"  onClick={this.onClickApply} id={"fqs-tag"}>Apply</button>
                        </div>

                        <div className='filterval'>
                            <div className='filterfield'>Subject: {this.state.subjects}</div>
                            <div className='filterfield'>Topic: {this.state.selectedtopic}</div>
                            <div className='filterfield'>Parent: {this.state.key}</div>
                            <div className='filterfield'>Tag: {this.state.tags}</div>
                            <div className='filterfield'>Index: {this.state.skip}</div>
                            
                        </div>
                        <div className="btn-group" role="group" aria-label="prev-next">
                            <button type="button" className="button" id="ss-prev" onClick={this.handlePrev}>Prev</button>
                            <button type="button" className="button" id="ss-next" onClick={this.handleNext}>Next</button>
                        </div>  
                </form>
                

        );
    }
    
    renderSearchSummary(){
        return(
            
            <Searchsummary data={this.state.searchsummary} handleq={this.handleQuest}/>
            
        );

    }

    
    render() {
        //console.log("Formsearchrequest: render ...State: ", this.state)

        let topicList =  <option key={"none-topic-qsf-1"} value="None">None</option> ;
        if(this.state.topics && this.state.topics.length > 0){
            topicList = this.state.topics.map((note) => <option key={note +"qsf-1"} value={note}>{note}</option> );
        }

        let baseQuestList =  <option key={"none-qsf-1"}  value="None">None</option> ;
        
        

        if(this.state.basequest!==undefined && this.state.basequest.length > 0){
            //console.log("Start rendering formquest search: ", this.state.basequest);
            baseQuestList = this.state.basequest.map((note) => <option id={note._id} key= {note._id} value={note.title}>{note.title}</option> );
            // baseQuestList.insert(0, <option id={'idnone'} key= {'idnone'} value={'Childs'}>{'Childs'}</option>)
        } 

        //console.log(baseQuestList);
        //console.log("XXXXXXXXXXXXXXX=key",this.state.key)
        return (
        <div className="qsearchcom">
            <div className="top">
                <h1>Search & Update Questions</h1>
            </div>
            <div className="middle">
                <div className="left">
                {this.renderSearchForm(topicList,baseQuestList)}
                </div>
                <div className="right">
                {this.renderSearchSummary()}
                </div>
            </div>
            <Updatequest qid={this.state.key}/>
            <div className='message'>
                <h1>WORK WISELY</h1>
                <h1>WORK WISELY</h1>
            </div> 
        </div>
        )
    }
    
}
