import React from 'react'
//import { featureHeader } from '../../data/data'
import { templates } from '../../data/data';
import "./institute.scss"
export default function Institute(props) {

  // var col1 =templates[props.counter].webMain1;
  // var col2 =templates[props.counter].webMain2;
  var col3 =templates[props.counter].webMain3;
  //var col4 =templates[props.counter].webMain4;
  return (
    <div className='institute'>

      <div className="top">
        <div className="title">
        <span className='dul'>Stepping Towards</span> <span className='highlight' style={{"background-color": col3}}>Smart Campus ...</span></div>
        <div className="subtitle">
        <i>Goal here is to leverage Artificial Intelligence (AI/ML) in any campus.</i>
        </div>
      </div>

      <div className="bottom" >
          <div className="one">
            <div className="imagecontainer">
              <img src="assets/digit_640.png" alt=""/>

            </div>  
            <div className="description">
                Digitize your Experience and Strengths
            </div>
          </div>
          <div className="two">
          <div className="imagecontainer">
          
              <img src="assets/geo_640.png" alt=""/>

          </div>    
          <div className="description">
              Optimize Campus across Geo-locations
              </div>            
          </div>
          <div className="three">
          <div className="imagecontainer">
                <img src="assets/campus_640.png" alt=""/>
          </div>
          <div className="description">
              Virtualization of Campus
              </div>            
          </div>
      </div>
      
    </div>
  )
}
