import React,{useState} from 'react'
import DataAPI from '../../api/DataAPI';
import Testmgmt from '../content/Testmgmt';
import { mgmt_testhist, mgmt_qlist} from '../../data/data'
import "./questionmgmt.scss"
//import { tabledata } from '../../data/data'



export default function Questionmgmt() {
  // const [email, setEmail] = useState(0);
  // const [skip, setSkip] = useState(0);
  const [tblData, settblData] = useState({id:"", data:[], skip:0,email:"", col:[]});
  // useEffect(() => {
  //   let filter={
  //   }

  //   if (tblData.id === "camplist_all"){
  //     new DataAPI().getTestHistAll(filter, (d)=>{
  //       console.log(d)
  //       settblData({data: d.data, col:mgmt_testhist, skip:tblData.skip});
  //     });
  //   } else if (tblData.id === "question_all"){
  //     let body={
  //       filter:filter,
  //       skip:tblData.skip
  //     }
  //     console.log("Filter: ",body)
  //     new DataAPI().getQuestionAll(body, (d)=>{
  //       console.log(d)
  //       settblData({data: d.data,col:mgmt_qlist,skip: tblData.skip});
  //     });
  //   }
  // });
  const handlePrev=(e)=>{
    let dd=tblData.skip;
    dd=dd-20;
    if(dd <=0){
      dd=0;
    } 
    handleChange(tblData.id, dd);
  }

  const handleNext=(e)=>{
    let dd=tblData.skip;
    dd=dd+20;
    handleChange(tblData.id, dd);
  }

  function handleChange(id, dd){
    let body={
      filter:{},
      skip:dd
    }

    if (id === "camplist_all"){
      new DataAPI().getTestHistAll(body, (d)=>{
        console.log(d)
        settblData({id: id,data: d.data, col:mgmt_testhist, skip:dd});
      });
    } else if (id === "question_all"){

      //console.log("Filter: ",body)
      new DataAPI().getQuestionAll(body, (d)=>{
        //console.log(d)
        settblData({id:id, data: d.data,col:mgmt_qlist,skip: dd});
      });
    }

  }
  const getAllData= async (e)=>{
    handleChange(e.target.id,0)
  }

  let datacol = tblData.col;

  //console.log("DATA==============", tblData)
  return (
    <div className='mgmt'>
        <div className="top">
                <h1>Data Management </h1>
        </div>

        <div className="bottom">
        <div className="search">
        <button id="camplist_all" type='button' onClick={getAllData} >Get Attempt History</button>        
        <button id="question_all" type='button' onClick={getAllData} >Get Questions</button>        
        </div>
        <div className="table">
        <Testmgmt histdata={tblData.data} datacol={datacol} shift={tblData.skip} prev={handlePrev} next={handleNext}/>
        </div>          
        
        </div>
        
    </div>

  )
}
// export default class List extends Component {
//   render() {
//     return (
//       <div className='testhist'>
//         <Camplist/>
//       </div>
//     )
//   }
// }
