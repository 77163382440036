import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Auth from '../../context/Auth';
import DataAPI from '../../api/DataAPI';
import {reason_list} from "../../data/data"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import "./widget.scss"
 ChartJS.register(ArcElement, Tooltip);


export default class Widget extends Component {

    constructor(){
      super();
      let auth = new Auth();
      this.state={
        email:auth.getEMail(),
        data:[],
        pdata:[],
        piedata:{
          labels: ['Conceptual', 'Calculation', 'Recalling', 'Reading', 'Concentration', "Misc"],
          datasets: [{
          label: '# of Votes',
          data: [52, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(153, 102,255, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 159, 64, 0.8)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },],},
        show:true
      }
    }
   
    options = {
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    
    getData(str){
        let data={};
        switch(str){

            case "maths":
                data={
                title: "MATHS",
                link: "See all details",
                icon: "function",
                bgcolor: "yellow",
                color: "white"    
                }
                break;
            case "chemistry":
                data={
                title: "CHEMISTRY",
                link: "See all details",
                icon: "science",
                bgcolor: "red",
                color: "white"    
                }
                break;
            case "biology":
                data={
                title: "BIOLOGY",
                link: "See all details",
                icon: "architecture",
                bgcolor: "green",
                color: "white"    
                }
                break;
            case "physics":
                data={
                title: "PHYSICS",
                link: "See all details",
                icon: "light",
                bgcolor: "purple",
                color: "white"    
                }
                break;
            default:
                data={
                title: "SCIENCE",
                link: "See all details",
                icon: "settings_motion_mode",
                bgcolor: "purple",
                color: "white"     
                }
        }

        return data;
    }

    processData(d)
    {
      //console.log("processData: ", d);      
      this.setState({data:d,
      show:false})
    }

    processPieData(d)
    {
      //console.log("processPieData: ", d);      
      var arr=[]
      reason_list.forEach(element => {
        //console.log(element.title, element.desc);
        if(d[element.title] !== undefined){
          arr.push(d[element.title])
        } else {
          arr.push(0)
        }
      });
      //console.log("arr",arr)
      var pidata = {
        labels: ['Conceptual', 'Calculation', 'Recalling', 'Reading', 'Concentration', "Misc"],
        datasets: [{
          label: '# of Votes',
        data: arr,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(153, 102,255, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102,255, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 159, 64, 1)',
        ],   
        borderWidth: 1,
      }]};      
      this.setState({pdata:d,
        piedata:pidata,
         show:false})
    }
    componentDidMount()
    {
     if(this.state.show === false) return;
 
     var filter={
       email: this.state.email,
       subject: this.props.type
     }
     //console.log("getSubjectTestData filter", filter);
     new DataAPI().getSubjectTestData(filter, this);
     new DataAPI().getSubjectGreyData(filter, this);
    }

  render() {
    const data = this.getData(this.props.type);
    const url ="/user/sdashboard/"+data.title;
    //console.log("this.state.piedata: ",data,"--",this.state.piedata)
    // var arr=[];
    // arr.push(this.state.piedata);
    // this.pidata.datasets[0].data=arr;
    return (
      <div className='widget'>
        <div className="left">
        {/* <span className="material-symbols-outlined">science</span> */}
        
            <div className="topicon">
              <span className="material-symbols-outlined icon" >{data.icon}
              </span>
            </div>
            <div className='subject'>{data.title}</div>
            <div className="marked">
                <span className='scored'>{this.state.data.correct===undefined?0:this.state.data.correct}</span>
                <span className='attempted'>{"/"+String((this.state.data.total===undefined)?0:this.state.data.total)}</span>
            </div>
            <Link to={url} className='Dashboard' 
            style={{ textDecoration: 'none' , color: 'inherit'}}>
              <span className='seeall'>More ...</span>
            </Link>
            
        </div>
        <div className="right">
        <Pie width="100px" data={this.state.piedata}  options={this.options}>
          </Pie>
        </div>
      </div>
    )
  }
}
