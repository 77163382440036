import React, { Component } from 'react'
import axios from 'axios';

import {uri} from '../../context/Auth';
import "./upload.scss"


export default class Uploadfiles extends Component {

    state = {
 
        // Initially, no file is selected
        selectedFile: null
      };
      
      // On file select (from the pop up)
      onFileChange = event => {
      
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
      
      };
      
      // On file upload (click the upload button)
      onFileUpload = () => {
      
        // Create an object of formData
        const formData = new FormData();
      
        // Update the formData object
        formData.append('photo',this.state.selectedFile);
        formData.append('fileName',this.state.selectedFile.name);
      
        // Details of the uploaded file
        console.log(this.state.selectedFile);
      
        // Request made to the backend api
        // Send formData object
        axios.post(uri+"/upload/images", formData).then((data) => {
          if(data !== undefined && data.data !== undefined && data.data.success === true){
            alert(data.data.data);
          } else {
            alert("Upload file failed. Please retry again with a different name");
          }
        });
      };
      
      // File content to be displayed after
      // file upload is complete
      fileData = () => {
      
        if (this.state.selectedFile) {
           
          return (
            <div>
              <h2>File Details:</h2>
               
  <p>File Name: {this.state.selectedFile.name}</p>
   
               
  <p>File Type: {this.state.selectedFile.type}</p>
   
               
  <p>
                Last Modified:{" "}
                {this.state.selectedFile.lastModifiedDate.toDateString()}
              </p>
   
            </div>
          );
        } else {
          return (
            <div>
              <br />
              <h4>Choose before Pressing the Upload button</h4>
            </div>
          );
        }
      };
      
      render() {
      
        return (

          <div className='upload'>
            <div className="topheader">
                <h1>Upload Image Files ...</h1>
            </div>
            <div className="topheader">
                    <input type="file" onChange={this.onFileChange} />
                    <button onClick={this.onFileUpload}>
                      Upload!
                    </button>
            </div>
            <div className='filedata'>
            {this.fileData()}
            </div>
              
        </div>
                  

        );
      }
}