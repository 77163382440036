
import React from 'react'

import LayoutBase from './LayoutBase'
import "./layout.scss"
var Latex = require("react-latex");
export default class Layout2 extends LayoutBase {

    constructor(){
        super();
        this.state.id="ly-2"
    }



  displayBody(){
    if(this.state.selected === 1){
        const data = this.props.data;
        if(data === undefined) return(<></>)

        return(
            <div className="row-body">

                <div className="imagerow">
                    <div className="image1">
                        <img src={this.imgurl+data.image[0]} 
                        alt="Logo"></img>
                    </div>                    
                    <div className="image1">
                        <img src={this.imgurl+data.image[1]} 
                        alt="Logo"></img>
                    </div>                    
                    <div className="image1">
                        <img src={this.imgurl+data.image[2]} 
                        alt="Logo"></img>
                    </div>                    
                    <div className="image1">
                        <img src={this.imgurl+data.image[3]} 
                        alt="Logo"></img>
                    </div>                    
                </div>
                <div className="row">
                    <div className="question">
                        Question{": "+data.title}
                    </div>
                </div>
                <div className="row">
                    {this.insertOption(data)}
                </div>
                <div className="rowbutton" onClick={this.handleSubmit}>
                    <div className="button" onClick={this.handleSubmit}>Save</div>
                </div>            
            </div>
        );         
    // return(
    //     <div className="camp-accord-body">
    //         <div className="row">
    //             <div className="col-3 border  bg-secondary">
    //                 IMAGE-1
    //             </div>
    //             <div className="col-3 border  bg-secondary">
    //                 IMAGE-2
    //             </div>                    
    //             <div className="col-3 border  bg-secondary">
    //                 IMAGE-3
    //             </div>                    
    //             <div className="col-3 border  bg-secondary">
    //                 IMAGE-4
    //             </div>                            
    //         </div>
    //         <div className="row">
    //                 <p className="card-text">
    //                     {data.desc}
    //                 </p>
    //                 {this.displayOptions(data)}
    //         </div>    
    //         <div className="row align-items-start bg-info" >
    //             <div className="btn btn-info " onClick={this.handleSubmit}>Save</div>
    //         </div>
    //     </div>
    // );
    } else {
        return (<></>);
    }
  }
    
  render() {
    const data = this.props.data;
    if(data === undefined) return(<></>)
    const latstr=`$$${data.desc}$$`
    return (
        <div className="layout">
            <div className="rowhead" onClick={this.onSelect}>
                <div className="qhead">
                    {this.displayStars(data)}
                    <b className='text-left'>Q-{data.sr}</b>
                </div>

                <div className="title">
                {(data.maths===true)?<>Question:  <Latex>Question: {latstr}</Latex></>:<>Question{": "+data.title}</>}
                    
                </div>
                <div className="state">
                {/* <span class="material-symbols-outlined">expand_less</span> */}
                    {this.displayState(data)}
                </div>

            </div>        
            {this.displayBody()}
            
        </div>
                
    )
  }
}
