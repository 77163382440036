import React, { Component } from 'react'
import Auth from '../../context/Auth';
import DataAPI from '../../api/DataAPI';
//import {study_comment} from '../../data/data'
import "./chart.scss"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="desc">{`${label} ${payload[0].payload.subject}`}</p>
        <hr></hr>
        <p className="label">{`Right/Wrong: ${payload[0].value} / ${payload[1].value}`}</p>
        <p className="label">{`Setname: ${payload[0].payload.setname}`}</p>
        <p className="label">{`Date: ${payload[0].payload.date}`}</p>
        <p className="labeluuid">{`Uuid: ${payload[0].payload.uuid}`}</p>

      </div>
    );
  }

  return null;
};

export default class Topicchart extends Component {


  constructor(){
    super();
    let auth = new Auth();
    this.state={
      email:auth.getEMail(),
      data:[],
      rawdata:[],
      topics:['data handling', "decimal"],
      show:true
    }
    this.onChangeTopic = this.onChangeTopic.bind(this);
  }

  onChangeTopic(e)
  {
    //console.log("onchange: ", e.target.value)
    if(e.target.value ==='All'){
      this.setState({data:this.state.rawdata,
        show:false})
    } else {
      var newdata=[];
      //console.log("Rawdata: ",this.state.rawdata)

      this.state.rawdata.forEach(function(value){
        //console.log("onchange: ",value)
        if(value.subject === e.target.value || value.subject.includes(e.target.value))
          newdata.push(value);
        
      });
      this.setState({data:newdata,
        show:false})

    }
      
  }
   // static demoUrl = 'https://codesandbox.io/s/stacked-area-chart-ix341';
   processData(d)
   {
    //console.log("Topicchart: ", d);

    var newdata=[]
    d.forEach(function(value){
      if (newdata.includes(value.subject)){
        //duplicate
      } else {
        newdata.push(value.subject);
      }
    });
    

     this.setState({data:d,rawdata:d,topics:newdata,
     show:false})
   }
   componentDidMount()
   {
    if(this.state.show === false) return;

    var filter={
      email: this.state.email,
      subject:this.props.subject
    }
    new DataAPI().getTopicTestData(filter, this);
   }

  render() {
    var size=50;
    var topicList = this.state.topics.map((note) => <option key={note +"tc-1"} value={note}>{note}</option> );
    if (this.state.data.length > 10){
      size=Math.ceil(500/this.state.data.length);
    }    
    return (
      <div className='chart'>
        <h3>{this.props.subject}</h3>
        <select className="form-select" aria-label="Select Subject" defaultValue={'None'} onChange={this.onChangeTopic} onClick={this.onChangeTopic}>
            <option key={"select-topic-tc"} value="All">All</option>
            {topicList}
        </select>
        <ResponsiveContainer width="100%" aspect={3/1}>
        <BarChart
          width={500}
          height={400}
          barSize={size}
          data={this.state.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip data={this.state.data}/>}/>
          <Bar type="monotone" dataKey="right" stackId="1" stroke='rgba(153, 102, 255, 1)' fill='rgba(153,102,255, 0.5)' />
          <Bar type="monotone" dataKey="wrong" stackId="1" stroke="grey" fill="lightgrey" />
          {/* <Bar type="monotone" dataKey="total" stackId="0" stroke="#ffc658" fill="#ffc658" /> */}
        </BarChart>
      </ResponsiveContainer>
      </div>
    )
  }
}
