
import React, { Component } from 'react'
import Auth from '../../context/Auth';
import DataAPI from '../../api/DataAPI';
import "./oldtest.scss"
var Latex = require("react-latex");

export default class Oldtest extends Component {
constructor(){
    super();    
    let auth = new Auth();
    let email=auth.getEMail();
    this.onClickApply=this.onClickApply.bind(this);
    this.onChangeUUID=this.onChangeUUID.bind(this);
    this.onClickNP=this.onClickNP.bind(this);
    this.state={
            uuid:"",
            data:[],
            email:email,
            index:0
    };
}

onClickNP(e){
    var ind=this.state.index;
    if(e.target.id === "oq-getprev"){
        if(ind>0){
            this.setState({
                index:ind-1
            })
        }
    } else if(e.target.id === "oq-getnext"){
        if(ind<19){
            var ii=ind+1;
            this.setState({
                index:ii
            })
        }
    }
    console.log("INDEX: ",this.state.index)
    this.onClickApply(e);
}

onChangeUUID(e){
    this.setState({
        uuid:e.target.value
    });
}


onClickApply(e){
    console.log()
    if (this.state.uuid !== ""){
        const filter={
            email: this.state.email,
            uuid: this.state.uuid,
            skip:this.state.index
        }

        new DataAPI().getQuestionSetByUUID(filter, this, (obj, pdata)=>{
            console.log("Data received: ",pdata)
            if(pdata.success === true && pdata.data!==undefined){
              obj.setState({
                  data:pdata.data,
                  starttime:new Date()
                })
            } else {
              alert("Resquest failed, Please retry");
            }
        });
    }
}


// componentDidMount()
// {
//     console.log("uuid", this.state.uuid);
//     if (this.state.uuid !== ""){
//         const filter={
//             email: this.state.email,
//             uuid: this.state.uuid,
//             skip:this.state.index
//         }

//         new DataAPI().getQuestionSetByUUID(filter, this, (obj, pdata)=>{
//             console.log("Data received: ",pdata)
//             if(pdata.success === true && pdata.data!==undefined){
//               obj.setState({
//                   data:pdata.data,
//                   starttime:new Date()
//                 })
//             } else {
//               alert("Resquest failed, Please retry");
//             }
//         });
//     }
// }


  render() {
    //console.log("Data: ",this.state.data)
    if(this.state.data.length <=0) {
        return (    <div className='oldtest'>
        <div className="top">
            <h1>Question Set (Read Only)</h1>
        </div>
        <div className="bottom">
            <div className='item'>
                <div className="label">Set Name: </div>
                <input type="text" className="form-control" id="oq-qset-1" placeholder="uuid of question set"  onChange={this.onChangeUUID}/>
                <button className="button" type="button" onClick={this.onClickApply} id={"oq-getdata"}>Find</button>
            </div>            
        </div>
        </div>
        )
    }
    var data=this.state.data[0];

    const latstr1=data.options[0].maths===true?<div className="latex"><Latex>{`$$${data.options[0].name}$$`}</Latex></div>:data.options[0].name;
    const latstr2=data.options[1].maths===true?<div className="latex"><Latex>{`$$${data.options[1].name}$$`}</Latex></div>:data.options[1].name;
    const latstr3=data.options[2].maths===true?<div className="latex"><Latex>{`$$${data.options[2].name}$$`}</Latex></div>:data.options[2].name;
    const latstr4=data.options[3].maths===true?<div className="latex"><Latex>{`$$${data.options[3].name}$$`}</Latex></div>:data.options[3].name;
    return (
    <div className='oldtest'>
        <div className="top">
            <h1>Question Set (Read Only)</h1>
        </div>
        <div className="bottom">
            <div className='item'>
                <div className="label">Set Name: </div>
                <input type="text" className="form-control" id="oq-qset-1" placeholder="uuid of question set"  onChange={this.onChangeUUID}/>
                <button className="button" type="button" onClick={this.onClickApply} id={"oq-getdata"}>Find</button>
            </div>            
        </div>
        <div className="bottom">
       <div className="control">
            <button className="button" type="button" onClick={this.onClickNP} id={"oq-getprev"}>Previous</button>
            <button className="button" type="button" onClick={this.onClickNP} id={"oq-getnext"}>Next</button>
        </div>
            <div className='qitem'>
                {
                    
                    this.state.data.map((e)=>{
                        return (<div className='quest'>
                        <h2><b>Question {this.state.index+1}: </b> {e.title}</h2>
                        <p style={(e.options[0].valid === true)?{"background-color":"green"}:{"background-color":"gray"}}><input className="check" type="checkbox" defaultChecked={e.anslist[0]} disabled/>(1) {latstr1}</p>
                        <p style={(e.options[1].valid === true)?{"background-color":"green"}:{"background-color":"gray"}}><input className="check" type="checkbox" defaultChecked={e.anslist[1]} disabled/>(2) {latstr2}</p>
                        <p style={(e.options[2].valid === true)?{"background-color":"green"}:{"background-color":"gray"}}><input className="check" type="checkbox" defaultChecked={e.anslist[2]} disabled/>(3) {latstr3}</p>
                        <p style={(e.options[3].valid === true)?{"background-color":"green"}:{"background-color":"gray"}}><input className="check" type="checkbox" defaultChecked={e.anslist[3]} disabled/>(4) {latstr4}</p>
                        </div>)
                    })
                }
            </div>    

       <div className="control">
            <button className="button" type="button" onClick={this.onClickNP} id={"oq-getprev"}>Previous</button>
            <button className="button" type="button" onClick={this.onClickNP} id={"oq-getnext"}>Next</button>
        </div>                    
        </div>
      
    </div>
  )}
}
