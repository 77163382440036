import React,{useEffect} from 'react'
import { Outlet,useNavigate } from 'react-router-dom'
import Sidebar from '../../components/sidebar/Sidebar'

import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import "../../global.scss"
import Auth from '../../context/Auth'
import "./applayout.scss";

export default function DashboardLayout(props) {

  const auth = new Auth();
  const logstate = auth.getLoggedInState();
  const navigate = useNavigate();
  

  useEffect(() => {
    if (logstate !== true){
    navigate("/login");
    }
  });

 
  return (
    <div className='applayout'>
    <Sidebar isSbarActive={props.isSbarActive} toggleSidebar={props.sstate}/>
    <div className="homecontainer">
      <Navbar sstate={props.sstate}/>

        <Outlet/>

      <Footer/>
    </div>
  </div>
  )
}
