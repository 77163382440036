import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/sidebar/Sidebar'

import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import "../../global.scss"
export default function AdminLayout() {
    return (
    <div className='applayout'>
    <Sidebar/>
    <div className="homecontainer">
        <Navbar></Navbar>

        <Outlet/>

        <Footer/>
    </div>
    </div>
    )
}
