import React, { Component } from 'react'
import Auth from '../../context/Auth';
import DataAPI from '../../api/DataAPI';
//import {study_comment} from '../../data/data'
import "./chart.scss"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {
  
  if (active && payload && payload.length) {

    return (
      <div className="custom-tooltip">
        <p className="desc">{`${label} ${payload[0].payload.subject}`}</p>
        <hr></hr>
        <p className="label">{`Set: ${payload[0].payload.setname}`}</p>
        <p className="label">{`Right/Wrong: ${payload[0].value} / ${payload[1].value}`}</p>
        <p className="label">{`Date: ${payload[0].payload.date}`}</p>
        <p className="labeluuid">{`Uuid: ${payload[0].payload.uuid}`}</p>

      </div>
    );
  }

  return null;
};

export default class Chart extends Component {


  constructor(){
    super();
    let auth = new Auth();
    this.state={
      email:auth.getEMail(),
      data:[],
      show:true
    }

  }

   // static demoUrl = 'https://codesandbox.io/s/stacked-area-chart-ix341';
   processData(d)
   {
    //console.log("Data &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&: ", d)
     this.setState({data:d,
     show:false})
   }
   componentDidMount()
   {
    if(this.state.show === false) return;

    var filter={
      email: this.state.email,
      subject:""
    }
    if(this.props.subject !== undefined){
      filter.subject=this.props.subject;
    }
    new DataAPI().getAllTestData(filter, this);
   }

  render() {
    var size=50;

    if (this.state.data.length > 10){
      size=Math.ceil(500/this.state.data.length);
    }
    //console.log(this.state.data)
    return (
      <div className='chart'>
        <ResponsiveContainer width="100%" aspect={3/1}>
        <BarChart
          width={500}
          height={400}
          barSize={size}
          data={this.state.data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip data={this.state.data}/>}/>
          <Bar type="monotone" dataKey="right" stackId="1" stroke='rgba(75, 192, 192, 1)' fill='rgba(75, 192, 192, 0.5)'/>
          <Bar type="monotone" dataKey="wrong" stackId="1" stroke="grey" fill="lightgrey" />
          {/* <Bar type="monotone" dataKey="total" stackId="0" stroke="#ffc658" fill="#ffc658" /> */}
        </BarChart>
      </ResponsiveContainer>
      </div>
    )
  }
}
