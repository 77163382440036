import React, { Component } from 'react'
import Auth from '../../context/Auth';
import DataAPI from '../../api/DataAPI';
import {study_comment} from '../../data/data'
import "./featured.scss"
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css"
export default class Featured extends Component {


  constructor(){
    super();
    let auth = new Auth();
    this.state={
      email:auth.getEMail(),
      value:0,
      comment:"No data found.",
      show:true,
      color:'green'
    };

    this.processData=this.processData.bind(this)

  }
  

  processData(d)
  {

   
    var value=d[0];

  if(value>95){
    this.setState({comment:study_comment[0].comment,value:value,
    show:false})
  } else if(value>80){
    this.setState({comment:study_comment[1].comment,value:value,
      show:false})
  } else if(value>60){
    this.setState({comment:study_comment[2].comment,value:value,
      show:false})
  } else if(value>50){
    this.setState({comment:study_comment[3].comment,value:value,
      show:false})
  } else {
    this.setState({comment:study_comment[4].comment,value:value,
      show:false})
  }

  }

  componentDidMount(){

    if(this.state.show === false) return;

    var filter={
      email: this.state.email,
      subject:""
    }

    if(this.props.subject !== undefined){
      filter.subject=this.props.subject;
    }

    new DataAPI().getOverAll(filter, this);
  }

  render() {
    //console.log("Rander: ",this.state.comment);
    return (
      <div className='featured'>
            <div className="ctop">
                <div className="title" >Progress</div>
            </div>

            <div className="bottom">
                <div className="featuredChart">
                    <CircularProgressbar value={this.state.value} text={String(this.state.value)+"%"} 
                    strokeWidth={5} 
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      textSize: '16px',

                    })}
                    />
                </div>
                <div className='comment'>
                  {this.state.comment}
                </div>
            </div>
      </div>
    )
  }
}
