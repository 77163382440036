import React, { Component } from 'react'
import Uploadimage from '../Uploadimage';
import DataAPI from '../../api/DataAPI';
import "./qsearchcom.scss"
import Auth, {axiosInstance, uri} from '../../context/Auth';
var Latex = require("react-latex");

export default class Updatequest extends Component {

    topics=[]
    selectedtopic=[]
    options=[
        {"id":0,"name":"",valid:0,"maths":false},
        {"id":1,"name":"",valid:0,"maths":false},
        {"id":2,"name":"",valid:0,"maths":false},
        {"id":3,"name":"",valid:0,"maths":false},
        {"id":4,"name":"",valid:0,"maths":false},
        {"id":5,"name":"",valid:0,"maths":false},
        {"id":6,"name":"",valid:0,"maths":false},
        {"id":7,"name":"",valid:0,"maths":false},
    ]
    constructor(){
        super();
        this.handleSubjectSelect=this.handleSubjectSelect.bind(this);
        this.handleReset=this.handleReset.bind(this);
        this.handleSearch=this.handleSearch.bind(this);

        this.handleCreatorOnChange = this.handleCreatorOnChange.bind(this);
        this.handleComplexOnChange = this.handleComplexOnChange.bind(this);
        this.handlePIDOnChange = this.handlePIDOnChange.bind(this);
        this.handleQOnChange = this.handleQOnChange.bind(this);
        this.handleTagOnChange = this.handleTagOnChange.bind(this);
        this.postTopicData = this.postTopicData.bind(this);
        this.setOptionsData = this.setOptionsData.bind(this);
        this.handleTopicSelect = this.handleTopicSelect.bind(this);
        this.handleImageOnChange=this.handleImageOnChange.bind(this);
        this.handleMath=this.handleMath.bind(this);

        this.state={
            subjects:[],
            topics:[]  ,
            selectedtopic: this.selectedtopic,
            selectedSubjects: [],
            title:"",
            desc: "",
            parentid: 0,
            id: "",
            tags:"",
            complex: 2,
            updated_by:new Auth().getEMail(),    
            image: [],        
            institute:"",
            options:this.options,
            seltopicindex:0,
            iddata:[],
            maths:false,
            loading: false
        };

    }


    dummyOnChange(e){
        
    }

    handleReset(e){

        alert(e.target.id)
        if(e.target.id === "uq-reset-subject"){
        this.setState({
            selectedSubjects:[],
            topics:[]
        });
    }
    }





    async handleSubjectSelect(e){
        if(e.target.value !=='all'){
            let pdata = this.state.selectedSubjects;
            pdata.push(e.target.value);
            
            //let topics = await new DataAPI().getTopics(pdata.toString());
            //new DataAPI().getTopics(pdata.toString(), function(topics){
            new DataAPI().getTopics(pdata.toString(), this,function(obj, topics){
            console.log("Updatequest: Topics Fetched: ",topics )
            if (topics.success === true){
            obj.setState({
                selectedSubjects: pdata.sort(),
                topics: topics.data[0].sort()
            });
            }
        });
        }
    }

    handleTopicSelect(e){
        //console.log("Topic selected ----------------", e.target.value)
        let tindex=this.state.topics.indexOf(e.target.value);
        this.setState({
            selectedtopic:[e.target.value],
            seltopicindex: tindex
        });
        console.log("this.state.seltopicindex: ", tindex, this.state.selectedSubjects[tindex])
    }

    handleTagOnChange(e){
       
        this.setState({
         tags:e.target.value
        });
    }


    handleQOnChange(e){
        //console.log(e.target.value)
        if(e.target.id === "qf-statictitle"){
            this.setState({
                title: e.target.value
            });
    
        } else if (e.target.id === "qf-staticdesc"){
            this.setState({
                desc: e.target.value
            });
    
        }
    }




    handlePIDOnChange(e){
        //console.log(e.target.value)
        this.setState({
            parentid: e.target.value
        });
    }

    handleComplexOnChange(e){
        //console.log(e.target.value)
        this.setState({
            complex: e.target.value
        });
    }
    
    handleCreatorOnChange(e){
        //console.log(e.target.value)
        // this.setState({
        //     updated_by: e.target.value
        // })
        
    }

    handleImageOnChange(e){

        //console.log("================================> handleImageOnChange");

        if(e.target){
            //console.log("Imagelist: ", e.target.value)
        if(e.target.value.length > 0){
            const imagelist=e.target.value.split(',')
            this.setState({
                image: imagelist
            })
        }
        } else {
            console.log("handleImageOnChange: ", e.target)
        }
    }

    postTopicData(e){
        e.preventDefault();

        if (this.state.id === undefined){
            alert("ID is not set.")
            return;
        }
        const postdata=[{
            "id": this.state.id,
            "title": this.state.title,
            "desc":this.state.title,
            "subjects": this.state.selectedSubjects,
            "tags": this.state.tags.split(','),
            "parentid": this.state.parentid,
            "topics": this.state.selectedtopic,
            "complex":this.state.complex,
            "updated_by": new Auth().getEMail(),
            "image":this.state.image,
            "institute":this.state.institute,
            "options":this.state.options
          }]        


          console.log("Posted Data: ", postdata);
          let tock = new Auth().getToken();
          axiosInstance.post('/questroute/updatequest', 
                        postdata,
                        {
                            headers: {'Content-Type': 'application/json', 'Accept': 'application/json',
                            'auth-token': tock}
                        }).then(
                            response=>{
                                console.log(response);
                                if(response.data.success === true){
                                    alert("question submitted successfully");
                                    this.setState({
                                        subjects:[],
                                        topics:this.topics  ,
                                        selectedtopic: this.selectedtopic, //reset to empty
                                        title:"",
                                        parentid: 0,
                                        tags:"",
                                        complex: 2,
                                        updated_by:new Auth().getEMail(),    
                                        image: [],        
                                        institute:"",
                                        options:this.options,
                                        loading: false
                                    })
                                    }                                
                            }
                        ).catch(error=>{
                            console.log(error)
                        }
                    )


    }
    
    async componentDidMount(){
        try{
            //const pdata = await new DataAPI().getAllSubjects();
            new DataAPI().getAllSubjects(this, (obj, pdata)=>{
            obj.setState({
                subjects: pdata.data.sort()
            });
        });
        }catch(err){

        }
    }

    setOptionsData(e){
        let id = e.target.id;
        // console.log("Option cliecked: ", id) 
        //console.log("Option cliecked: ", e.target.checked) 
        let ids=id.split('-');
        let ind=Number(ids[1])-1
        // console.log("option: ", ind,this.options[ind]) 
        let opt=this.state.options;
        if(ids[0] === 'uqoptiontext'){
            this.options[ind].name=e.target.value
            opt[ind].name=e.target.value
            
        } else if (ids[0] === 'uqoptionchk'){
            this.options[ind].valid=e.target.checked
            opt[ind].valid=e.target.checked
        } else if (ids[0] === 'emathopt'){
            this.options[ind].maths=e.target.checked
        }

        this.setState({
            options: opt,
        });
        //console.log(this.options)

    }

  insertOption(ind){

    // let opt=undefined;
    // if(this.state.options !== undefined){
    //     opt=this.state.options[ind-1]
    // }
    //console.log(opt)




    let latstr="";
    let bvalid = false;
    let bmaths = false; 
    if (this.state.options[ind-1] !== undefined)
    {
        bmaths=this.state.options[ind-1].maths;
        if(bmaths){
            latstr=`$$${this.state.options[ind-1].name}$$`
        } else {
            latstr=this.state.options[ind-1].name;
            bvalid=this.state.options[ind-1].valid;
        }
    } else {
        latstr="";
    }

    
    
    // console.log('this.state.options[ind-1].maths',this.state.options[ind-1].maths)
    //  console.log("ind",latstr)
    //  console.log("valid, index",this.state.options[ind-1].valid,(ind-1))
    //  console.log("latstr----",this.state.options[ind-1].name)

    return (
            <div className="option">
                <label htmlFor={"staticopt-"+ind} className="form-label">Option-{ind}</label>
                <div className="input-group-text">
                        M<input className="form-check-input" type="checkbox" onChange={this.setOptionsData} id={"emathopt-"+ind} aria-label="Enable Maths" checked={bmaths}/>
                        A<input className="form-check-input" type="checkbox" onChange={this.setOptionsData} id={"uqoptionchk-"+ind} aria-label="Checkbox for following text input" checked={bvalid}/>
                </div>
                <input type="text" id={"uqoptiontext-"+ind} onChange={this.setOptionsData} className="form-control" aria-label="Text input with checkbox" value={latstr}/> 
                    {(bmaths ===true)?<Latex>{latstr}</Latex>:<></>}
            </div>
    )
  }

  handleMath(e)
  {
    if(this.state.maths === false){
        this.setState({maths:true});
    } else {
        this.setState({maths:false});
    }
        
  }
  renderSubjectInput(){
    let subjectlist;
    if(this.state.subjects.length >0){
        subjectlist = this.state.subjects.map((note) => <option key={note +"fcs-1"} value={note}>{note}</option> );
    } else {
        subjectlist = <option key={"uq-none-1"} value="None">None </option> ;
    }
    //console.log("renderSubjectInput: ",this.state.subjects)

    return (
        <>

                <div className="input-group">
                    <label htmlFor="uq-inputSubject" className="col-sm-2 col-form-label">Subjects</label>
                    <select className="form-select" aria-label="Select Subject" onChange={this.handleSubjectSelect} >
                        {subjectlist}
                    </select>
                    <button className="btn btn-outline-secondary" type="button" onClick={this.handleReset} id={"uq-reset-subject"}>Reset</button>
                    <input type="text" className="col-sm-8 form-control" onChange={this.handleSubjectSelect} id="uq-selsubject"  placeholder={this.state.selectedSubjects.toString()} disabled/>
                </div>
        </>
    );
  }


  renderTopicInput(){
    const topicList = this.state.topics.map((note) => <option key={note +"uq-tpk-1"} value={note}>{note}</option> );
    return (
        <>
            <label htmlFor="uq-inputTopic" className="col-sm-2 col-form-label">Topics</label>
            <div className="container col-sm-10 my-2">
            <select className="form-select" onClick={this.handleTopicSelect} id="uq-topic" aria-label="Select Topics" defaultValue={'None'} >
                {topicList}
            </select>
            <input type="text" className="form-control" id="uq-seltopic"  placeholder={this.state.selectedtopic.toString()} disabled/>
            </div>

        </>
    );
  }

  async handleSearch(e){
    //console.log("Search: ",e.target.id, " Value ", e.target.value)

    if(e.target.id === 'uq-search-txt'){
        this.setState({
            id: e.target.value
        });
    } else if (e.target.id === 'uq-search-btn'){
        //console.log("file data into form.")

        const filter={
            id: this.state.id
        }

        new DataAPI().getQuestByID(filter, this, (obj, pdata)=>{
            console.log("Response received for given id:", pdata);
            if (pdata.success === true){
    
                obj.setState({
                    iddata:pdata.data,
                    selectedSubjects:pdata.data.subjects,
                    selectedtopic: pdata.data.topics,
                    title: pdata.data.title,
                    maths: pdata.data===undefined?false: pdata.data.maths,
                    desc: pdata.data.desc,
                    tags: pdata.data.tags.toString(),
                    image:pdata.data.image,
                    institute: pdata.data.institute === undefined?"":pdata.data.institute,
                    complex: pdata.data.complex,
                    updated_by: new Auth().getEMail(),
                    parentid: pdata.data.parentid,
                    options:pdata.data.options
                });
    
            }
    
        });

    }
}

  render() {
    //console.log("Updatequest Render: ", this.state.topics, " loading: ",this.setOptionsData.loading);

    //console.log(this.state.iddata);
    if(this.props.qid !== this.state.id){
        this.setState({
            id:this.props.qid
        });
    }

    var img = this.state.image.map((e)=>{return( <img src={uri+"/upload/images/"+e} alt="Logo"></img>)})
    return (
    <div className='bottom'>

        <h1>Update Question</h1>

    <form>
        
        <div className="input-group">
            <input type="text" className="form-control" placeholder="QID" aria-label="QID" id="uq-search-txt" 
                onClick={this.handleSearch} onChange={this.handleSearch} aria-describedby="uq-search-btn" value={this.state.id}/>
            <button className="button" type="button" id="uq-search-btn" onClick={this.handleSearch}>Search</button>
        </div>

        {this.renderSubjectInput()}
        
        <div className="input-group">
            {this.renderTopicInput()}
        </div>

        <div className="input-group">
            <label htmlFor="statictitle" className="col-sm-2 col-form-label">Question</label>
            <input type="text" className="form-control" onChange={this.handleQOnChange} id="qf-statictitle"  
                placeholder="Write basic question here" value={this.state.title}/>
        </div>

        <div className="input-group">
            <label htmlFor="staticdesc" className="col-sm-2 col-form-label">Description</label>
            
            <input type="text" className="form-control" onChange={this.handleQOnChange} id="qf-staticdesc"  
                placeholder="Write basic question here" value={this.state.desc}/>
            
        </div>
        <div className="input-group">
            <label htmlFor="statictag" className="col-sm-2 col-form-label">Tags</label>
            <input type="text" className="form-control" onChange={this.handleTagOnChange} 
                id="qf-statictag"  placeholder="Comma seperated tags" value={this.state.tags}/>
        </div>
        <div className="image1">
            {img}
                        {/* <img src={uri+"/upload/images/"+this.state.image[0]} alt="Logo"></img> */}
                    </div>  
        <div className="input-group" >
                <label htmlFor="staticimagecon" className="col-sm-2 col-form-label">Image</label>
                <input type="text" className="form-control" onChange={this.handleImageOnChange} onClick={this.handleImageOnChange} 
                    id="qf-staticimagecon"  placeholder="Image Name" value={this.state.image}/>
        </div>
  
        <div className="input-group">
                    <label className="form-check-label" >Enable Maths</label>
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckMathenable" onChange={this.handleMath}/>
        </div>


    </form>
    <form>        

        <div className="options">
        {this.insertOption(1)}
        {this.insertOption(2)}
        {this.insertOption(3)}
        {this.insertOption(4)}
        {this.insertOption(5)}
        {this.insertOption(6)}
        {this.insertOption(7)}
        {this.insertOption(8)}
        </div>
    </form>
    <form>        

        <div className="input-group">
            <label htmlFor="staticparentid" className="col-sm-2 col-form-label">ParentID</label>
            <input type="text" className="form-control" onChange={this.handlePIDOnChange} 
                id="qf-staticparentid"  placeholder="Comma seperated tags" value={this.state.parentid}/>

        </div>
        <div className="input-group">        
            <label htmlFor="staticcomplex" className="col-sm-1 col-form-label">Complexity</label>
            <input type="text" className="form-control" onChange={this.handleComplexOnChange} 
                id="qf-staticcomplex" placeholder="1-5 i.e  1- low, 5- high" value={this.state.complex}/>
        </div>
        <div className="input-group">        

            <label htmlFor="staticcreator" className="col-sm-1 col-form-label">Updated By</label>
            <input type="text" className="form-control" onChange={this.handleCreatorOnChange} 
                id="qf-staticcreator" placeholder="Name of creator" value={this.state.updated_by}/>
        </div>

        <div className="input-group">
        <button onClick={this.postTopicData} className="button">Submit</button>
        </div>

      </form>

      </div>    
    )
  }
    
}

