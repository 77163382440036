import React from 'react'
import { featureHeader } from '../../data/data'
import "./headpage.scss"
export default function Headpage() {
  return (
    <div className='headpage'>
          {/* <img src="assets/abstract-main-485_1280.jpg" alt=""/> */}
          <div className="pheader">
            {
            featureHeader.map(d=>(
            <div className="topcard">
            <div className="topicon">
            <span className="material-symbols-outlined">{d.icon}</span>
            </div>
            <div className="toptitle">
            <h3>{d.title}</h3>
            </div>
            </div>

            ))
            }

      </div>
    </div>
  )
}
