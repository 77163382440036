import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Chart from '../../components/chart/Chart'
import Topicchart from '../../components/chart/Topicchart'
import Topicgreychart from '../../components/chart/Topicgreychart'
import Featured from '../../components/featured/Featured'
import Widget from '../../components/widget/Widget'

import "./dashboardsubject.scss"

export default class Dashboardsubject extends Component {
    constructor(props){
        super(props)
    }
  render() {
    const  subject  = this.props.subject;
   
    return (
      <div className='dashboardsubject'>
           <div className="top">
                <h1>Subject Details </h1>
                <Link to="/user/dashboard" className='backlink'>
                <span className="material-symbols-outlined">arrow_back</span>
                </Link>
            </div>
          <div className="widgets">
              <Widget type={subject}/>
          </div>
          <div className='charts'>
            <Featured className="featured" subject={subject}/>
            <Chart className="chart" subject={subject}/>
          </div>
          <div className='charts'>
          <Topicchart className="chart" subject={subject}/>
          </div>
          <div className='charts'>
          <Topicgreychart className="chart" subject={subject}/>
          </div>          
          <div className='message'>
                <h1>WORK HARD & WISELY</h1>
            </div>          
        </div>

  
        
        
    )
  }
}
