
export const dataIntro = [
    {
     id:"2",
     title:"Artifical Intelligence",
     desc:"Evaluation of core concepts, Quick and Precise quidance with the help of artifical intellegence (AI).",
     icon:"psychology",
     img:"assets/head-intro.jpg"
   },{
     id:"1",
     title:"DEPTH of Concept",
     desc:"A set of selective questions, not only gives you a different perspective to think-on but helps identifing GRAY areas to work-on. TRY once. It will make you more confident." ,
     icon:"insights",
     img:"assets/sea-640.jpg"
   },
   {
     id:"3",
     title:"Confidence and Determination",
     desc:"Ensuring Move in right direction with rewards and recognitions in term of knowledge, concets & processes, followed in the school/institutions to optimize students/faculty time efficiently",
     icon:"Landscape",
     img:"assets/mountain.jpg"
   },
   {
     id:"4",
     title:"READY to go ..",
     desc:"Just select your subjects/topics to prepare your question set. Rate question based on complexity and also raise question for your friends.",
     icon:"Flag",
     img:"assets/ready.jpg"
   }
   

 ];

 export const templates =[
    {
    webMain1: "#001253",
    webMain2: "#3E6D9C",
    webMain3: "#FD841F",
    webMain4: "#E14D2A",
    },{
    webMain1: "#FA7070",
    webMain2: "#A1C298",
    webMain3: "#C6EBC5",
    webMain4: "#FBF2CF",
    },{
    webMain1: "#425F57",
    webMain2: "#749F82",
    webMain3: "#A8E890",
    webMain4: "#CFFF8D",
    },{
    webMain2: "#76BA99",
    webMain1: "#876445",
    webMain3: "#CA955C",
    webMain4: "#EDDFB3",
    },{
    webMain2: "#1C6758",
    webMain1: "#3D8361",
    webMain3: "#D6CDA4",
    webMain4: "#EEF2E6",
    },{
    webMain1: "#0c1116",
    webMain2: "#2a3b49",
    webMain3: "#5887ad",
    webMain4: "#fba92c",
    }
 ]

export const productlist =[
  {
    id:"edu-app",
    active:1,
    title:"Education Application"
  },
  {
    id:"mob-apps",
    active:0,
    title:"Mobile Apps"
  },
  {
    id:"Design",
    active:0,
    title:"Design"
  },
  {
    id:"Branding",
    active:0,
    title:"Branding"
  }  
  
];


export const eduapp =[
  {
    id:"edu-app",
    title:"•	Dynamic Assessments ",
    img:"assets/exam_640.jpg"
  },
  {
    id:"quick-test",
    title:"•	Concept Building",
    img:"assets/gray_640.jpg"
  },
  {
    id:"edu-app",
    title:"•	Recorded Sessions Mgmt",
    img:"assets/e-learning_640.jpg"
  },
  {
    id:"edu-app",
    title:"•	Precise Guidance",
    img:"assets/teacher_640.jpg"
  } 

];

export const mobapp =[
  {
    id:"edu-app",
    title:"Cash Receipts",
    img:"assets/cashreceipt1.jpg"
  },
  {
    id:"edu-app",
    title:"School bus tracking",
    img:"assets/bustrack.png"
  },
  {
    id:"edu-app",
    title:"Outdoor billing and Accounting",
    img:"assets/cashreceiptnew.jpg"
  },
  {
    id:"edu-app",
    title:"Parking Management",
    img:"assets/parking_1024_500.jpg"
  }


];

export const wip =[
  {
    id:"edu-app",
    title:"Work In-Progress",
    img:"assets/wip_640.jpg"
  }

];

export const featureHeader =[
  {
    id:"edu-feature",
    title:"Students",
    icon:"Local_library",
  },{
    id:"edu-feature",
    title:"Institute/Schools",
    icon:"School",
  },{
    id:"edu-feature",
    title:"Faculty",
    icon:"Group",
  }
]

export const edufeature =[
  {
    id:"edu-feature",
    title:"Smart Content",
    icon:"psychology_alt",
    desc:"Smart digitized content and flexible to expand"
  },{
    id:"edu-feature",
    title:"Tracking and Scheduling",
    icon:"monitoring",
    desc:"Schdule notification, track and evaluate preperations"
  },{
    id:"edu-feature",
    title:"Auto generation",
    icon:"smart_toy",
    desc:"Generate study content based on needs"
  },{
    id:"edu-feature",
    title:"Quick Revisions",
    icon:"local_library",
    desc:"If short in time, efficient revision contents."
  },{
    id:"edu-feature",
    title:"Participation",
    icon:"volunteer_activism",
    desc:"All to participate in content creation and evaluation as open platform"
  },{
    id:"edu-feature",
    title:"Help Friends ",
    icon:"thumb_up",
    desc:"Prepare and share contents to your friends"
  }

];


export const tabledata =[
  {category: "Sporting Goods", price: "$49.99", stocked: true, name: "Football"},
  {category: "Sporting Goods", price: "$9.99", stocked: true, name: "Baseball"},
  {category: "Sporting Goods", price: "$29.99", stocked: false, name: "Basketball"},
  {category: "Electronics", price: "$99.99", stocked: true, name: "iPod Touch"},
  {category: "Electronics", price: "$399.99", stocked: false, name: "iPhone 5"},
  {category: "Electronics", price: "$199.99", stocked: true, name: "Nexus 7"}
];


export const aboutus =[
  {
    id:"aboutus",
    title:"AboutUs",
    img:"assets/grow_640.jpg"
  },
  {
    id:"edu-app",
    title:"About Company",
    desc: "Coming soon ..."
  },
  {
    id:"edu-app",
    title:"Mobile App3",
    img:"assets/teacher_640.jpg"
  }

];

export const testhistcolumn =[
  {
    id:"thc-1",
    title:"S.N",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-2",
    title:"Date",
    img:"icon_name",
    width:"100px"
  },  {
    id:"thc-21",
    title:"Name",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-3",
    title:"ID",
    img:"icon_name",
    width:"200px"
  },  {
    id:"thc-4",
    title:"Total",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-5",
    title:"Correct",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-6",
    title:"Wrong",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-7",
    title:"Status",
    img:"icon_name",
    width:"100px"
  }



]




export const syllabus = {
    "maths":
{
  "VI": [
    {
      chapter: 1,
      name: "Number System",
      topics:[
          "Knowing our Numbers",
          "Playing with Numbers",
          "Whole numbers",
          "Negative Numbers and Integers",
          "Fractions",
      ]
    },
    { 
      chapter: 2,
      name: "Algebra",
      topics:[
          "Introduction to Algebra",
      ]
    },
    { 
      chapter: 3,
      name: "Ratio and Proportion",
      topics:[
        "Concept of Ratio",
        "Proportion as equality of two ratios",
        "Unitary method",
        "Word problems",
      ]
    },
    { 
      chapter: 4,
      name: "Geometry",
      topics:[
        "Basic geometrical ideas (2 -D)",
        "Understanding Elementary Shapes (2-D and 3-D)",
        "Symmetry",
        "Constructions (using Straight edge Scale, protractor, compasses)",
      ]
    },
    { 
      chapter: 5,
      name: "Mensuration",
      topics:[
        "Concept of Perimeter and Introduction to Area"
      ]
    },
    { 
      chapter: 6,
      name: "Data handling",
      topics:[
        "Basic Understanding of Data, Pictograph and Handing"
      ]
    }
  
        ],
  "VII": [
    {
      chapter: 1,
      name: "Number System",
      topics:[
        "Knowing our Numbers",
        "Fractions and rational numbers",
        "Powers",
      ]
    },
    { 
      chapter: 2,
      name: "Algebra",
      topics:[
        "Algebraic Expressions"
      ]
    },
    { 
      chapter: 3,
      name: "Ratio and Proportion",
      topics:[
        "Percentage an introduction",
        "Application to simple interest"
      ]
    },
    { 
      chapter: 4,
      name: "Geometry",
      topics:[
        "Understanding shapes",
        "Properties of triangles",
        "Symmetry",
        "Representing 3-D in 2-D",
        "Congruence",
        "Construction (Using scale, protractor, compass)",
      ]
    },
    { 
      chapter: 5,
      name: "Mensuration",
      topics:[
        "Concept of measurement",
        "Area"
      ]
    },
    { 
      chapter: 6,
      name: "Data handling",
      topics:[
        "Mean, median and mode of ungrouped data",
        "Constructing bargraphs"
      ]
    }
  
        ],
  "VIII": [
    {
      chapter: 1,
      name: "Number System",
      topics:[
        "Rational Numbers",
        "Powers",
        "Squares, Square roots, Cubes, Cube roots",
        "Playing with numbers",
      ]
    },
    { 
      chapter: 2,
      name: "Algebra",
      topics:[
          "Multiplication and division of algebraic exp",
          "Identities",
          "Factorisation",
          "Solving linear equations in one variable"
      ]
    },
    { 
      chapter: 3,
      name: "Ratio and Proportion",
      topics:[
        "applications on percentages, profit & loss",
        "simple and compound interest",
        "Direct variation",
        "Inverse variation",
        "Time & work problems"
      ]
    },
    { 
      chapter: 4,
      name: "Geometry",
      topics:[
        "Understanding shapes",
        "Representing 3-D in 2-D",
        "Construction",
        "Constructions (using Straight edge Scale, protractor, compasses)",
      ]
    },
    { 
      chapter: 5,
      name: "Mensuration",
      topics:[
        "Area of a trapezium and a polygon",
        "Concept of volume",
        "Volume and capacity",
        "Surface area of a cube, cuboid,cylinder."
      ]
    },
    { 
      chapter: 6,
      name: "Data handling",
      topics:[
          "Reading bar-graphs,",
          "Simple Pie charts",
          "Consolidating and generalising the notion of chance in events"
      ]
    },
    { 
      chapter: 7,
      name: "Introduction to graphs",
      topics:[
          "Axes (Same units), Cartesian Plane",
          "Plotting points",
          "Reading off from the graphs"
      ]
    }    
  
        ]   
    },
    "all":
    {
    "VI": [],
    "VII":[],
    "VIII":[]  
    },
    "science":
    {
    "VI": [
      {
        chapter: 1,
        name: "Food: Where Does It Come From?",
        topics:[
            "Food Variety",
            "Food Materials and Sources",
            "Plant Parts and Animal Products as Food",
            "Plant parts as food",
            "What do Animals Eat?",
        ]
      },      
      {
        chapter: 2,
        name: "Components of Food",
        topics:[
            "What do Different Food Items Contain?",
            "What do Various Nutrients do For Our Body?",
            "Balanced Diet",
            "Deficiency Diseases"
        ]
      },
    ],
    "VII":[],
    "VIII":[]  
    },
    "physics":
    {
    "VI": [],
    "VII":[],
    "VIII":[]  
    },
    "chemistry":
    {
    "VI": [],
    "VII":[],
    "VIII":[]  
    }        

}


export const mgmt_testhist =[
  {
    id:"thc-1",
    title:"S.N",
    col:"sn",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-2",
    title:"Date",
    col:"date",
    img:"icon_name",
    width:"100px"
  },  {
    id:"thc-21",
    title:"EMail",
    col:"email",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-3",
    title:"Subject",
    col:"subject",
    img:"icon_name",
    width:"200px"
  },  {
    id:"thc-4",
    title:"Topics",
    col:"topics",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-5",
    title:"Status",
    col:"status",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-6",
    title:"Status",
    col:"status",
    img:"icon_name",
    width:"50px"
  }
]

export const mgmt_qlist =[
  {
    id:"thc-1",
    title:"S.N",
    col:"sn",
    img:"icon_name",
    width:"50px"
  }, {
    id:"thc-5",
    title:"Date",
    col:"date",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-2",
    title:"Title",
    col:"title",
    img:"icon_name",
    width:"400px"
  },  {
    id:"thc-21",
    title:"Subject",
    col:"subjects",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-3",
    title:"Tags",
    col:"tags",
    img:"icon_name",
    width:"50px"
  },  {
    id:"thc-3",
    title:"Empty",
    col:"tags",
    img:"icon_name",
    width:"50px"
  }, {
    id:"thc-4",
    title:"Topics",
    col:"topics",
    img:"icon_name",
    width:"50px"
  }
]

export const reason_list=[
  {
    id:"reason-1",
    title:"CON",
    desc:"Conceptual",    
  },  {
    id:"reason-2",
    title:"CAL",
    desc:"Calculation",    
  },  {
    id:"reason-3",
    title:"REC",
    desc:"Recalling",    
  },  {
    id:"reason-4",
    title:"RED",
    desc:"Reading",    
  },  {
    id:"reason-5",
    title:"COT",
    desc:"Concentration",    
  },  {
    id:"reason-6",
    title:"MIS",
    desc:"Miscellaneous",    
  }

]

export const links_list=[
  {
    id:"reason-1",
    title:"YOU",
    desc:"Youtube link",    
  },  {
    id:"reason-2",
    title:"INT",
    desc:"Internet Link",    
  },  {
    id:"reason-3",
    title:"Doc",
    desc:"Document",    
  },  {
    id:"reason-6",
    title:"MIS",
    desc:"Miscellaneous",    
  }

]

export const study_comment=[
  {
    id:"1",
    comment:"Excellent"
  },{
    id:"2",
    comment:"Well Done. Keep trying for excellent"
  },{
    id:"2",
    comment:"You can do well"
  },{
    id:"3",
    comment:"Work Hard"
  },{
    id:"4",
    comment:"Review again and Check with your teachers."
  }

]
