import React,{useState } from 'react'
import DataAPI from '../../api/DataAPI';
import Auth from '../../context/Auth';
import Camplist from '../content/Camplist'
import "./testhist.scss"
//import { tabledata } from '../../data/data'



export default function Testhist() {
  //const [email, setEmail] = useState(0);
  const [tblData, settblData] = useState([]);
  // useEffect(() => {
  //  // let auth = new Auth();
  //  // setEmail(new Auth().getEMail());


  // });

  const getAllData= async (e)=>{
    let filter={
      email: new Auth().getEMail()
    }
    new DataAPI().getTestHist(filter, (d)=>{
      console.log(d)
      settblData(d.data);
    });
  }
  return (
    <div className='testhist'>
        <div className="top">
                <h1>Test Records </h1>
        </div>

        <div className="bottom">
        <div className="search">
        <button id="camplist_all" type='button' onClick={getAllData}>Get All Data</button>        
        </div>
        <div className="table">
        <Camplist histdata={tblData}/>
        </div>          
        
        </div>
        
    </div>

  )
}
// export default class List extends Component {
//   render() {
//     return (
//       <div className='testhist'>
//         <Camplist/>
//       </div>
//     )
//   }
// }
