import React, { Component } from 'react'
import FormComSubjects from '../FormComSubject'
import "./topiccom.scss"
import DataAPI from '../../api/DataAPI';
import Auth,{axiosInstance} from '../../context/Auth';
import {syllabus} from "../../data/data"
export default class Topiccom extends Component {
    subjectName="all";
    topicdata=[];
    title=""
    

    constructor(){
        super();
        var cls = new Auth().getClass();  
        this.getSubject = this.getSubject.bind(this);
        this.getTopics=this.getTopics.bind(this);
        this.postTopicData=this.postTopicData.bind(this);
        this.handleOnChange=this.handleOnChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        
        
        this.state={
            subjectName:"all",
            title:"",
            data:this.topicdata,
            tags:[],
            clstag: cls

        };
    }

  
  getSubject(data)
  {
    console.log("FormTopics: ",data)
    this.subjectName=data;
    this.getTopics();

    this.componentDidMount1();
  }  

  async getTopics(){

  }
  componentDidMount1(){
    
    //const url='http://localhost:4000/v1/contents/topics/'+this.subjectName
   // const pdata=await new DataAPI().getTopics(this.subjectName)
    //new DataAPI().getTopics(this.subjectName, function(pdata){

    let name=this.subjectName
    if(this.state.tags.length> 0){
        name=name+"/tags/"+this.state.tags.toString();
    }
    console.log("name: ",name);
    new DataAPI().getTopics(name, this,function(obj, pdata){
        if(pdata.success === true){
            let newdata = pdata.data[0].sort();
            obj.setState({
                data: newdata,
                subjectName:name,
                loading: true
            })
        }
    });

  }

  handleSearch(e){
    e.preventDefault();
    let name=this.subjectName
    if(this.state.tags.length> 0){
        name=name+"/tags/"+this.state.tags.toString();
    }
    console.log("handleSearch->name1: ",name);

    new DataAPI().getTopics(name, this,function(obj, pdata){
        if(pdata.success === true){
            let newdata = pdata.data[0].sort();
            obj.setState({
                data: newdata,
                subjectName:name,
                loading: true
            })
        }
    });
  }

  render() {

    //console.log("Render Topics: ",this.state.data)
    const topicList = this.state.data.map((note) => <li key={note+"-formtopic-2"} className="list-group-item">{note}</li> );
    return (
      <div className="topiccom">
            <div className="top">
                <h1>Configure Topics</h1>
            </div>
            <div className="bottom">
            <div className="left">
            <form>
                <div className="form-group">
                    <FormComSubjects title="subject" datafunc={this.getSubject} multisel={false}/>
                </div>    
                <div className="form-group">
                    <label htmlFor="topic-1">Topic name (max 10 words)</label>
                    <input type="text" className="form-control" onChange={this.handleOnChange} id="topic-1" aria-describedby="topicHelp" placeholder="Write here"/>    
                </div>
                <div className="form-group">
                    <label htmlFor="topic-tag-1">Tags </label>
                    <input type="text" className="form-control" onChange={this.handleOnChange} id="topic-tag-1" aria-describedby="topicHelp" placeholder="single words, comma seperated for multiple && no space in between"/>    
                </div>
                <button onClick={this.postTopicData} className="button">Submit</button>
                <button onClick={this.handleSearch} className="button">Search</button>
            </form>                
            </div>

            <div className="right">
                <ul className="list-group">
                    {topicList}
                </ul>                
            </div>
            </div>

            <div className='bottom'>
            <div className="left2">
                <p>
                    titile: {this.state.title},
                    subject: {this.state.body},
                    tags: {this.state.tags.toString()}
                </p>
            </div>
            </div>
            <div className="syllabus">
            <div className='top'>
                <h2>Syllabus</h2>
            </div>
            {/* <div className="bottom">
                {
                    
                    syllabus[this.state.subjectName][this.state.clstag].map((d)=>                           
                            <div className="chapter">{d.chapter} {d.name}
                                    {d.topics.map((l)=><li>{l}</li>)}
                            </div>    
                        )
                    
                }
                </div> */}
        </div>

      </div>
    )
  }

  handleOnChange(e){
    console.log(e)

    if(e.target.id === "topic-1"){
        const li = this.state.subjectName.split(',');
        this.setState(
            {
                title:e.target.value,
                body:li
            }
        );
    
    } else if(e.target.id === "topic-tag-1"){
        let dd = e.target.value.trim();
        if (dd.length > 0){
            let arr = dd.split(',');
            
            this.setState(
                    {
                    tags:arr
                }
            );
        }
        } else {

    }
}
  
  async postTopicData(e){
    e.preventDefault();
    // console.log("------------------------",this.state.subjectName)
    // console.log("------------------------",this.state.title)
    if (this.state.subjectName ==='all' || this.state.subjectName.length <=0){
        alert("Please select subject")
        return;
    }

    let strtitle=this.state.title.trim();
    if (strtitle.length <= 3){
        alert("Topic name is too small")
        return;
    }

    const li = this.state.subjectName.split(',');
    const tg = this.state.tags;
    const postdata={
        "title": strtitle,
        "type": "topic",
        "body": li,
        "tags": tg
      }        

      let tock = new Auth().getToken();  
      axiosInstance.post('/contents/content', postdata,
        {
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
            'auth-token': tock}
        }).then(
        response=>{
            console.log("Response data: ",response.data);
            if(response.data && response.data.success === true){
                alert("Topic submitted successfully");            
                // this.setState({
                //     title:"",
                //     data:this.topicdata,
                //     tags:this.statetopicdata
                // })
            } else if(response.data) {
                alert(response.data.data)
            } else {
                alert("Request failed.")
            }
        }
      ).catch(error=>{
        console.log(error)
      })

    }


}
