import React, { Component } from 'react'
import Intro from '../../components/home/Intro';
import Products from '../../components/home/Products';
import Testimonials from '../../components/home/Testimonials';
import ContactUs from '../../components/home/ContactUs';
import AboutUs from '../../components/home/AboutUs';

import "./home.scss"
import Institute from '../../components/home/Institute';
import FooterPage from '../../components/home/FooterPage';
import Headpage from '../../components/home/Headpage';

export default class Home extends Component {

  constructor(){
    super();
    var val = Math.floor(Math.random() * 5);
    this.state={
      counter: val
    }
  }

  render() 
  {
    
    return (
      <div className='myhome'>
              <Intro counter={this.state.counter}/>
              <Institute counter={this.state.counter}/>
              <Headpage counter={this.state.counter}/>
              <Testimonials counter={this.state.counter}/>
 
              <Products counter={this.state.counter}/>
              <ContactUs counter={this.state.counter}/>
              <AboutUs counter={this.state.counter}/>
              <FooterPage counter={this.state.counter}/>
      </div>
    )
  }
}


