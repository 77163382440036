
import Auth,{uri,axiosInstance} from '../context/Auth';


export default class DataAPI {

    createfilter(subjects=[], topics=[],id="", tags=[])
    {
        let filter ={
            subjects:subjects,
            topics: topics,
            id:id,
            tags:tags,
        };
        //console.log("Received data : -> ",filter)


        if(filter.id !== ""){
            filter={id:id};
            return filter;
        } 
        //console.log("Created filter: -> ",filter)
        return filter;
    }


     getBaseQuest(filter, obj, func){
        try{
            //console.log("getBaseQuest: Filter applied: ",filter);
            let tock = new Auth().getToken();
            axiosInstance.post(uri+'/questroute/getparentquest', 
                filter,
                {
                    headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                    'auth-token': tock}
                }).then(
                    response=>{
                        //console.log("API response received: ", response.data, response.data.success);

                        if(response.data.success === true){
                            
                            func(obj,response.data);
                        } else {
                            alert(response.data.data);
                            func(obj,[]);
                        }                                
                    }
                ).catch(error=>{
                    console.log(error)
                    func(obj,[]);
                }
            )

        } catch(err){
            throw err;
        }

    }


    async getChildQuest(filter, obj, func){
        try{
            // console.log("********** getChildQuest")
            // console.log(filter);
            let tock = new Auth().getToken();
            axiosInstance.post(   uri+'/questroute/getchildquestbyid', 
            filter,
            {
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                'auth-token': tock}
            }).then(
                response=>{
                    //console.log("API response received: ", response.data, response.data.success);

                    if(response.data.success === true){
                        
                        return func(obj, response.data);
                    } else {
                        alert(response.data.data);
                        return func(obj,[]);
                    }                                
                }
            ).catch(error=>{
                console.log(error)
            }
            )
        } catch(err){
            throw err;
        }

    }

     getQuestByID(filter, obj, func){
        try{
            // console.log("********** getChildQuest")
            // console.log(filter);
            let tock = new Auth().getToken();
            axiosInstance.post(   uri+'/questroute/getchildbyid', 
            filter,
            {
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                'auth-token': tock}
            }).then(
                response=>{
                    //console.log("API response received: ", response.data, response.data.success);

                    if(response.data.success === true){
                        
                        return func(obj,response.data);
                    } else {
                        alert(response.data.data);
                        return func(obj,[]);
                    }                                
                }
            ).catch(error=>{
                console.log(error)
            }
            )
        } catch(err){
            throw err;
        }

    }



    getTopicList(filter, obj, func){
        try{
            //console.log("getTopicList filter: ",filter)
            var auth = new Auth();
            const tags=auth.getTag();
            const subject=filter.subjects[0];
            const url=uri+'/contents/topics/'+subject+"/tags/"+tags
            //console.log("URL: ", url)
            //console.log("Subject: ",subject);
            let tock = auth.getToken();

            fetch(url,
                {
                    headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                    'auth-token': tock}
                }).then(res => res.json()).then(data=>{
                    if(data.success === true){
                        func(obj, data);
                    } else {
                        alert(data.data)
                    }
                });

        }catch(err){
            return [];
        }
    }


    getTopics(sdata, obj, func){
        try{
        const url=uri+'/contents/topics/'+sdata
        //console.log("URL: ", url)
        let tock = new Auth().getToken();
//        let data = await fetch(url, { mode:'cors'})
        fetch(url,
            {
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                'auth-token': tock}
            }).then(res => res.json()).then(data=>{
            //console.log("Data: ", data);
            if(data.success === true){
                func(obj, data);
            } else {
                func(obj, data.data)
            }
        });        
        // let pdata = await data.json() 
        // console.log("Topics: ",pdata)        
        // return pdata;
        }catch(err){
            console.log("getTopics: ",err)
        }
        
    }

    getAllSubjects(obj, func){
        try{
            let url = uri+'/contents/subjects';
            //console.log("getAllSubjects");
            let tock = new Auth().getToken();
            fetch(url,
                {
                    headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                    'auth-token': tock}
                }).then(res => res.json()).then(data=>{
                //console.log("Data: ", data);
                if(data.success === true){
                  //let pdata = data;
                  func(obj, data);        
                } else {
                    alert(data.data)
                    func(obj, []);
                }
            })
            
           
            
        }catch(err){
            console.log("getAllSubjects: ",err)
            return [];
        }
        
    }	


    changePassword(filter, obj, func){
        try{
            // console.log("********** changePassword")
            // console.log(filter);
            let tock = new Auth().getToken();
            axiosInstance.post(   uri+'/validate/changepwd', 
            filter,
            {
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                'auth-token': tock}
            }).then(
                response=>{
                    //console.log("API response received: ", response.data, response.data.success);

                    if(response.data.success === true){
                        
                        func(obj, response.data);
                    } else {
                        alert(response.data.data);
                        func(obj, []);
                    }                                
                }
            ).catch(error=>{
                console.log(error)
            }
            )
        } catch(err){
            throw err;
        }

    }


    async resetPassword(filter){
        try{
            // console.log("********** changePassword")
            // console.log(filter);
            let tock = new Auth().getToken();
            const data= await axiosInstance.post(   uri+'/reset/pass/'+filter.auth, 
            filter,
            {
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                'auth-token': tock}
            }).then(
                response=>{
                    //console.log("API response received: ", response.data, response.data.success);

                    if(response.data.success === true){
                        
                        return response.data;
                    } else {
                        alert(response.data.data);
                        return [];
                    }                                
                }
            ).catch(error=>{
                console.log(error)
            }
            )
            return data;
        } catch(err){
            throw err;
        }

    }


    async forgetPassword(filter){
        try{
            // console.log("********** forgetPassword")
            // console.log(filter);
            let tock = new Auth().getToken();
            const data= await axiosInstance.post(   uri+'/validate/chpass', 
            filter,
            {
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
                'auth-token': tock}
            }).then(
                response=>{
                    //console.log("API response received: ", response.data, response.data.success);
                    if(response.data.success === true){
                    return response.data.data;
                    } else {
                        alert(response.data.data);
                    }
                }
            ).catch(error=>{
                console.log(error)
            }
            )
            return data;
        } catch(err){
            throw err;
        }

    }


async createUser(udata, obj, func){
    try{
        // console.log("********** createUser" + uri)
        // console.log(udata);
        let tock = new Auth().getToken();
        const data= await axiosInstance.post(   uri+'/validate/newuser', 
        udata,
        {
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
            'auth-token': tock}
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    
                    return func(obj, response.data);
                } else {
                    alert(response.data.data);
                    return func(obj, []);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        //return data;
    } catch(err){
        throw err;
    }

}

async updateUser(udata, obj, func){
    try{
        // console.log("********** updateUser")
        // console.log(udata);
        let tock = new Auth().getToken();
        const data= await axiosInstance.post(   uri+'/validate/updateuser', 
        udata,
        {
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
            'auth-token': tock}
        }).then(
            response=>{
                //console.log("updateUser API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    alert(response.data.data);
                    return func(obj,response.data);
                } else {
                    // alert(response.data.data);
                    return func(obj,[]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        return data;
    } catch(err){
        throw err;
    }

}


getUser(filter, obj, func){
    try{
        // console.log("********** createUser")
        // console.log(filter);

        //let auth = new Auth();
        let tock = new Auth().getToken();

        axiosInstance.post(   uri+'/validate/user', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 
            'auth-token': tock}
        }).then(
            response=>{
                //console.log("getUser API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    
                    return func(obj, response.data);
                } else {
                    alert(response.data.data);
                    return func(obj, []);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }
    return [];

}



async savePaper(udata, obj, func){
    try{
        // console.log("********** createUser")
        // console.log(udata);

        //let auth = new Auth();
        let tock=new Auth().getToken();

        //console.log("Received token: ",tock)
        axiosInstance.post(   uri+'/user/savepaper', 
        udata,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    
                    return func(obj,response.data);
                } else {
                    alert(response.data.data);
                    return func(obj,[]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )

    } catch(err){
        throw err;
    }

}




async getPaperList(udata){
    try{
        // console.log("********** createUser")
        // console.log(udata);

        //let auth = new Auth();
        let tock=new Auth().getToken();

        //console.log("Received token: ",tock)
        const data= await axiosInstance.post(   uri+'/user/getpaper', 
        udata,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);
                if(response.data.success === true){
                    
                    return response.data;
                } else {
                    alert(response.data.data);
                    return [];
                }                              
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        //console.log("returning data",data)
        return data;
    } catch(err){
        throw err;
    }

}

async getQuestionSet(filter, obj, func){
    try{
        //console.log("********** getQuestionSet")
        //console.log(filter);
        //let auth = new Auth();
        let tock=new Auth().getToken();

        //console.log("Received token: ",tock)

        axiosInstance.post(   uri+'/user/getqlist', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);
                if(response.data.success === true){
                    func(obj, response.data);
                } else {
                    alert(response.data.data);
                    return func(obj,response.data.data);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}

async getQuestionSetByUUID(filter, obj, func){
    try{
        //console.log("********** getQuestionSetByUUID")
        //console.log(filter);
        //let auth = new Auth();
        let tock=new Auth().getToken();

        //console.log("Received token: ",tock)

        axiosInstance.post(   uri+'/user/getqset', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);
                if(response.data.success === true){
                    func(obj, response.data);
                } else {
                    alert(response.data.data);
                    return func(obj,response.data.data);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}

async getAnswerSet(filter){
    try{
        //console.log("********** getAnswerSet")
        //console.log(filter);
        //let auth = new Auth();
        let tock=new Auth().getToken();

        //console.log("Received token: ",tock)

        const data= await axiosInstance.post(uri+'/user/postanswer', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    
                    return response.data;
                } else {
                    alert(response.data.data);
                    return [];
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        return data;
    } catch(err){
        throw err;
    }

}

async getTestHist(filter, func){
    try{
        //console.log("********** getTestHist")
        //console.log(filter);
        //let auth = new Auth();
        let tock=new Auth().getToken();

        //console.log("Received token: ",tock)

        axiosInstance.post(uri+'/user/testhist', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    
                    func(response.data);
                } else {
                    alert(response.data.data);
                    func([]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        return [];
    } catch(err){
        throw err;
    }

}

async getTestHistAll(filter, func){
    try{
        //console.log("********** getTestHistAll")
        //console.log(filter);
        //let auth = new Auth();
        let tock=new Auth().getToken();

        //console.log("Received token: ",tock)

        axiosInstance.post(uri+'/user/testhistall', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    
                    func(response.data);
                } else {
                    alert(response.data.data);
                    func([]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        return [];
    } catch(err){
        throw err;
    }

}

async getQuestionAll(filter, func){
    try{
        //console.log("********** getQuestionAll")
        //console.log(filter);
        //let auth = new Auth();
        let tock=new Auth().getToken();

        //console.log("Received token: ",tock)

        axiosInstance.post(uri+'/questroute/getallquest', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    
                    func(response.data);
                } else {
                    alert(response.data.data);
                    func([]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        return [];
    } catch(err){
        throw err;
    }

}

async sendMessage(udata, obj,func){
    try{
        console.log("********** sendMessage")
        console.log(udata);

        //let auth = new Auth();
        // let tock=new Auth().getToken();
        // console.log("Received token: ",tock)

        axiosInstance.post(   uri+'/msg/msg', 
        udata,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
//            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    return obj.func(response.data);
                } else {
                    //alert(response.data.data);
                    return obj.func([]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}

getOverAll(filter,obj,func){
    try{
        //console.log("********** getOverAll")
        //console.log(filter);

      
        let tock=new Auth().getToken();

        axiosInstance.post(   uri+'/perform/getoverall', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    var wrong=0;
                    var correct=0;
                    

                    if(response.data.data[0]['_id'] === false){
                        wrong=response.data.data[0].sum;
                    } else {
                        correct=response.data.data[0].sum;
                    }
                    if(response.data.data[1]['_id'] === true){
                        correct=response.data.data[1].sum;
                    } else {
                        wrong=response.data.data[1].sum;
                    }
                    //console.log("correct",correct, "wrong", wrong)
                    var result = Math.ceil(correct*100/(wrong+correct));

                    
                    return obj.processData([result]);
                } else {
                    //alert(response.data.data);
                    return obj.processData([-1]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}

getAllTestData(filter,obj,func){
    try{
        //console.log("********** getOverAll")
        //console.log(filter);

        
        let tock=new Auth().getToken();

        axiosInstance.post(   uri+'/perform/gettestdata', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    return obj.processData(response.data.data);
                } else {
                    return obj.processData([]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}

getSubjectTestData(filter,obj,func){
    try{
        console.log("********** getSubjectTestData")
        console.log(filter);

       
        let tock=new Auth().getToken();

        axiosInstance.post(   uri+'/perform/getsubjecttestdata', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                console.log("getSubjectTestData API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    var wrong=0;
                    var correct=0;
                    
                    if(response.data.data[0] === undefined) {
                        return obj.processData({correct:0,total:0});;
                    }

                    if(response.data.data[0]['_id']['result'] === false){
                        wrong=response.data.data[0].sum;
                    } else {
                        correct=response.data.data[0].sum;
                    }
                    if(response.data.data[1]['_id']['result'] === true){
                        correct=response.data.data[1].sum;
                    } else {
                        wrong=response.data.data[1].sum;
                    }
                    //console.log("correct",correct, "wrong", wrong)
                    var result = {
                        correct:correct,
                        total:correct+wrong
                    };

                    
                    return obj.processData(result);
                } else {
                    //alert(response.data.data);
                    return obj.processData({
                        correct:0,
                        total:0
                    });
                }                                 
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}

getSubjectGreyData(filter,obj,func){
    try{
        //console.log("********** getSubjectGreyData")
        //console.log(filter);

       
        let tock=new Auth().getToken();

        axiosInstance.post(   uri+'/perform/getsubjectgreydata', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("getSubjectTestData API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    return obj.processPieData(response.data.data);
                } else {
                    //alert(response.data.data);
                    return obj.processPieData({"CAL":1});
                }                                 
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}


getTopicTestData(filter,obj,func){
    try{
        //console.log("********** gettestdatabytopic")
        //console.log(filter);

      
        let tock=new Auth().getToken();

        axiosInstance.post(   uri+'/perform/gettestdatabytopic', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("getTopicTestData API response received: ", response.data, response.data.success);

                if(response.data.success === true){
                    return obj.processData(response.data.data);
                } else {
                    return obj.processData([]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}

getTopicGreytData(filter,obj,func){
    try{
        //console.log("********** getTopicGreytData")
        //console.log(filter);

      
        let tock=new Auth().getToken();

        axiosInstance.post(   uri+'/perform/gettopicgreydata', 
        filter,
        {
            headers: {'Content-Type': 'application/json', 
            'Accept': 'application/json',
            'auth-token': tock
            }
        }).then(
            response=>{
                //console.log("getTopicGreytData API response received: ", response.data, response.data.success);


                if(response.data.success === true){
                    return obj.processData(response.data.data);
                } else {
                    return obj.processData([]);
                }                                
            }
        ).catch(error=>{
            console.log(error)
        }
        )
        
    } catch(err){
        throw err;
    }

}
}
