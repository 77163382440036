import React, { useState } from 'react'

import "./login.scss"
import {Link, useNavigate} from "react-router-dom"
import Auth,{axiosInstance} from '../../context/Auth';
import Footer from '../../components/footer/Footer';
export default function Login() {

    
    const navigate = useNavigate();
    const [email, setEmail]=useState("");
    const [password, setPass]=useState("");
    const auth = new Auth()
    const {logIn,logOut} = auth.getContext();




    return (


        <div className="login">
            
                <form>
                <div className="block">
   
                <div className="item">
                    <span className="logo"><img src="./logo512.png" alt=""/></span>
                       
                    <div>
                        <h2>Welcome!</h2>
                    </div> 
                    <div className='text'>
                        <div>Sign in</div>
                    </div>    
                </div>

                <div className="item">
                <div className='email'>
                    <input
                        placeholder="E-Mail Address"
                        type="email"
                        name="login-email"
                        className='inputdata'
                        onChange={(e)=>{setEmail(e.target.value)}}
                    />
                </div>
                <div className='password'>
                    <input
                        placeholder="Password"
                        type="password"
                        name="login-password"
                        className='inputdata'
                        onChange={(e)=>{setPass(e.target.value)}}
                    />
                </div>
                <div className='inputdata'>
                    <button className='button' onClick={onSubmit}>Login</button>
                </div>
                <div className='register'>
                <Link to="/new" className='registerlink'>
                  <span>New User? Register Now!</span>
                </Link> 
                <Link to="/forget" className='forget'>
                  <span>Forget Password?</span>
                </Link> 

                </div>
                </div>
                </div>
                </form>

                <Footer/>
        </div>
    )




    function onSubmit(e) {
    
        e.preventDefault();
        //console.log("onSubmit", e.target.value)
        
        
            const postdata = {
                email: email,
                password: password
            }
            
            axiosInstance.post('/validate/login', postdata,
            {
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
            }).then(
                response=>{
                    //console.log("Login  response : ",response);
                    if(response !== undefined && response.data.success === true){
                        
                        //console.log("response.data: ",response.data)
                        logIn(response.data,true)
                        
                        navigate("/user")
                    } else {
                        logOut();
                        alert("Authentication failed. " + String(response.data.data))
                        
                    }
                }
            ).catch(error=>{console.log(error)});
    
        }
    


}


