
import {
  Routes,
  Route
} from "react-router-dom";
import About from "./pages/about/About";
import Dashboard from "./pages/dashboard/Dashboard";

import Login from "./pages/login/Login";
import Home from "./pages/home/Home";


import { useState } from "react";

import PublicLayout from "./pages/dashlayout/PublicLayout";
import DashboardLayout from "./pages/dashlayout/DashboardLayout";
import Starttest from "./components/test/Starttest";
import TestPaper from "./components/test/TestPapers";
import Newuser from "./components/user/Newuser";
import AdminLayout from "./pages/dashlayout/AdminLayout";
import Changepasswdcom from "./components/user/Changepasswdcom";
import Error from "./pages/Error";
import Admindash from "./components/admindash/Admindash";
import Subjectcom from "./components/content/Subjectcom";
import Topiccom from "./components/content/Topiccom";
import Questioncom from "./components/content/Questioncom"
import Qsearchcom from "./components/content/Qsearchcom"
import Profile from "./components/user/Profile";
import Forget from "./components/user/Forget";
import Uploadfiles from "./pages/content/Uploadfiles";
import Testhist from "./components/test/Testhist";
import Questionmgmt from "./components/test/Questionmgmt";
import Oldtest from "./components/test/Oldtest";
import Dashboardsubject from "./pages/dashboard/Dashboardsubject";
import Userprofile from "./components/user/Userprofile";
import Topiclink from "./components/content/Topiclink";
function App() {
  const [sidebar, setSidebar] =useState(false);
 

  
  
  

  const toggleSidebar=()=>{
    setSidebar((prevState)=>!prevState) 
    console.log(sidebar)
  }


  //console.log("Repainting .... ", {sidebar});


  return (
    
    <>
 
    <Routes>
      <Route path="/" element={<PublicLayout/>} >
        <Route index element={<Home/>} />
        <Route path="home" element={<Home/>} />
        <Route path="about" element={<About/>} />
        <Route path="login" element={<Login/>} />
        <Route path="new" element={<Newuser/>} />
        <Route path="forget" element={<Forget/>} />

        
      </Route>
      
      <Route path="/user" element={<DashboardLayout  
        sstate={toggleSidebar} 
        setsbar={setSidebar}
        isSbarActive={sidebar}/>}>
        <Route index element={<Dashboard/>} />
        <Route path="dashboard" element={<Dashboard/>} />
        <Route exact path="sdashboard/maths" element={<Dashboardsubject subject="maths"/>} />
        <Route exact path="sdashboard/science" element={<Dashboardsubject subject="science"/>} />
        <Route exact path="sdashboard/chemistry" element={<Dashboardsubject subject="chemistry"/>} />
        <Route exact path="sdashboard/physics" element={<Dashboardsubject subject="physics"/>} />
        <Route path="preptest" element={<TestPaper/>} />
        <Route path="testhist" element={<Testhist/>} />
        <Route path="starttest" element={<Starttest/>} />
        <Route path="oldtest" element={<Oldtest/>} />
        <Route path="chpass" element={<Changepasswdcom/>}/>
        <Route path="profile" element={<Userprofile/>} />
        
        
      </Route>
      <Route path="/admin" element={<AdminLayout/>}>
        <Route index element={<Admindash/>} />
        <Route path="subject" element={<Subjectcom/>}/>
        <Route path="topic" element={<Topiccom/>}/>
        <Route path="topiclink" element={<Topiclink/>}/>
        <Route path="question" element={<Questioncom/>}/>
        <Route path="qsearch" element={<Qsearchcom/>}/>
        <Route path="uploadfiles" element={<Uploadfiles/>} />
        <Route path="mgmt" element={<Questionmgmt/>} />
      </Route>
      <Route path="*" element={<Error/>}></Route>
    </Routes>

    </>
  );
}


export default App;
