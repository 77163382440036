import React, { Component } from 'react'
import Auth from '../../context/Auth';
import DataAPI from '../../api/DataAPI';
import Layout1 from './Layout'
import Layout2 from './layout2'
import Layout3 from './Layout3'
import "./layoutheader.scss"
var uuid = require('uuid');

export default class Layoutheader extends Component {
  
  constructor(){
    super();
    let auth = new Auth();
    let email=auth.getEMail();
    this.state={
      qlist:[],         //question set name list
      qset:[],          //question list
      qname:"",
      uuid:"",
      totoalquest:0,
      loading:false,
      starttime:new Date(),
      time:new Date(),
      email: email,
      start: false,
      right:[],
      show:false          //list of answers
    }
    this.handleStart=this.handleStart.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.updateStats=this.updateStats.bind(this);
    this.diff_minutes=this.diff_minutes.bind(this);
    this.handleSubmit=this.handleSubmit.bind(this);
  }


  async handleStart(){

    if(this.state.start === true){
      alert("Question set is already initiated. Please submit this set")
      return;
    }
    //console.log("Start solving this set ===================> for test paper: ", this.state);

    if(this.state.qname === "" || this.state.qname==='none'){
      alert("Please select question set");
      return;
    }
    const filter={
      email: this.state.email,
      name: this.state.qname
    }
    new DataAPI().getQuestionSet(filter, this, (obj, pdata)=>{
      //console.log("Data received: ",pdata)
      if(pdata.success === true && pdata.data!==undefined){
        let arr=pdata.data.map(()=>-1);
        //console.log("Result array: ", arr)
        obj.setState({
            qset:pdata.data,
            totoalquest:pdata.data.length,
            uuid: uuid.v1(),
            start:pdata.data.length >0?true:false,
            right: arr,
            starttime:new Date()
          })
      } else {
        alert("Resquest failed, Please retry");
      }
    });


  }

  handleChange(e){
    this.setState({
      qname:e.target.value}
      );
  }

  async componentDidMount(){

    if(this.state.email === undefined){
      return;
    } else {

    this.myinterval=setInterval(() => {
      this.setState({
        time: new Date()
      })
    }, 60000);


    const filter={
      email:this.state.email
    }
    
    let slist = await new DataAPI().getPaperList(filter)
    //console.log("Response  received: ", slist);
    if(slist !== undefined && slist.success === true){

       this.setState({
        qlist: slist.data,
        loading: true,
        time: new Date()
      })
    } else {
      console.error("Relogin. Session expired.")
    }
  }
  }

  updateStats(ind,res){
    //console.log("Update count:",ind, " ", res)
    let arr= this.state.right;
    arr[ind-1]=res===true?1:0;
    this.setState({
      right: arr
    })
  }

  handleSubmit(e){

    if (this.state.totoalquest <= 0){
      alert("Please selet a question set and then start");
      return;
    }

    let attempted=this.state.totoalquest;
    this.state.right.forEach(function(v) {
      if (v === -1) attempted--;
    });

    //console.log("this.state.attempted", this.state.attempted)
    //console.log("this.state.totoalquest", this.state.totoalquest)

    if (attempted !== this.state.totoalquest){
      alert("Please attempt all questions and save answers.");
      return;
    }

    this.setState({
      show:true
    });

    clearInterval(this.myinterval);
  }

  diff_minutes() 
 {
  var diff =(this.state.starttime.getTime() - this.state.time.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
 }

  render() {

        //console.log("Layoutheader render ----------------",this.state)
        if(this.state.email === undefined){
          return(<h3>Please login </h3>);
        }

        let attempted=this.state.totoalquest;
        this.state.right.forEach(function(v) {
          if (v === -1) attempted--;
        });

        let lst =  this.state.qlist;
        //console.log(lst);
        if(lst === undefined ){
          lst=[]
        }
        const noteItems = lst.map((note) => <option key={note+"lhdr-1"} value={note}>{note}</option> );
        const func=this.updateStats;
        let sn=1;
        const show=this.state.show;
        const setid=this.state.uuid;
        const uname=this.state.uname;

        const qItems = this.state.qset.map(
          function(q){
            q.sr=sn++
            
            const len = q.image !== undefined? q.image.length:0;

            //console.log("q=",len, "data=",q)
            if( len <= 0){
              return (<Layout1 setid={setid} setname={uname} data={q} updatefunc={func} show={show}/>)  
            } else if( len === 1 && q.image[0] !==""){
              return (<Layout2 setid={setid} setname={uname} data={q}  updatefunc={func} show={show}/>)  
            } else if( len === 1 ){
              return (<Layout1 setid={setid} setname={uname} data={q}  updatefunc={func} show={show}/>)  
            } else {  
              return (<Layout3 setid={setid} setname={uname} data={q}  updatefunc={func} show={show}/>)  
            }
            
          }
        );
        
          //console.log(qItems);
        let rtans=" -"
        if(this.state.show === true){
          let rt=0;
          this.state.right.forEach(function(v) {
            if (v === 1) rt++;
          });
          rtans=rt;
        }
    
        return (
        <div className='layoutheader'>
          <div className="top">
            <h1>Start your test ...</h1>
          </div>
          <div className="bottom">
          <div className="items">
            <div className="label">Select Question Set</div>
            <div className='qselect'>
              <select className="form-select col-8 my-1" aria-label="Select Question Set" defaultValue={'none'} onChange={this.handleChange} id="lhdr-staticqlist">
                        <option key={"lhdr-heading-1"} value="None">Select Question Set</option>
                        {noteItems}
                        </select>
              <button className="button" type="button" onClick={this.handleStart} id={"lhdr--addon1"}>Start</button>
            </div>          
          </div>

          <div className="resultbar">
            <div className="left">
            <div className="item">
              <div>ID: {this.state.uuid}<b>[</b> {this.state.qname}<b>]</b></div>
              <div>Total Questions: {this.state.totoalquest} </div>
            </div>

            </div>
            <div className="middle">
              <div className="time">Start Time (Min): {this.diff_minutes()}
              </div>
              <div className="attempt">Attempted: {attempted}/{this.state.totoalquest} Right Ans:{rtans}
              </div>            
            </div>
            <div className="right">
              <div className="item">
                  <button className="button" onClick={this.handleSubmit}>Submit</button>
              </div>

            </div>
          </div>
          <div className="qlist">
            {qItems}
          </div>  

            <div className="resultbar">
            <div className="left">
              <div className="item">
                    <div>ID: {this.state.uuid}</div>
                    <div><b>[</b> {this.state.qname}<b>]</b></div>
              </div>
              <div className="item">
                <div className='totq'>Attemped: {attempted}/{this.state.totoalquest} 
                </div>
              

              </div>

            </div>
            <div className="middle">
              <div className="time">Start Time (Min): {this.diff_minutes()}
              </div>
            </div>
            <div className="right">
            <div className="item">
                <button className="button" onClick={this.handleSubmit}>Submit</button>
            </div>

            </div>
          </div>
            
            <div className='space'>
              <div>END</div>
              <div></div>
            </div>

          </div>
        </div>
        )
  }
}
